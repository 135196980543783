import {
  Autocomplete,
  Box, Button, TextField, Typography, lighten, styled,
} from '@mui/material';
import LengthUnitPicker from 'components/units/LengthUnitPicker';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function AutocompleteAndUnitSelect({
  columnMappings, col, handleMappingChange, validCsvHeaders,
  isCsvHeaderValid, handleUnitChange,
}) {
  const { t } = useTranslation();
  const isMultiple = col.type === 'multi-string';
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleAutocompleteChange = (event, newInputValue) => {
    handleMappingChange(col.name, col.type, newInputValue);
  };

  const options = [
    ...(col.suggestion !== undefined ? [col.suggestion] : []),
    ...validCsvHeaders,
  ];

  const groupOfOption = {
    ...validCsvHeaders.reduce((acc, curr) => {
      acc[curr] = 'header_from_existing';
      return acc;
    }, {}),
    [col.suggestion]: 'header_new',
  };

  const GroupItems = styled('ul')({
    padding: 0,
  });

  const value = columnMappings[col.name]?.csvName || (isMultiple ? [] : null);

  return (
    <Box sx={{
      display: 'flex', gap: 1, alignItems: 'center', flexDirection: 'column', width: '100%',
    }}
    >
      <Box sx={{
        display: 'flex', gap: 1, alignItems: 'center', width: '100%',
      }}
      >
        <Autocomplete
          disableCloseOnSelect={isMultiple}
          freeSolo={!col.requiresExistingColumn}
          multiple={isMultiple}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          sx={{ flexGrow: 1 }}
          value={value}
          onChange={handleAutocompleteChange}
          onInputChange={!col.requiresExistingColumn ? handleAutocompleteChange : undefined}
          options={options}
          groupBy={(option) => groupOfOption[option]}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader
                text={t(`create_inventory.${params.group}`)}
                isMultiple={isMultiple}
                handleClose={handleClose}
              />
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              sx={{ width: '100%' }}
              label={t(`barrel_inventory.column_options.${col.name}`)}
            />
          )}
        />
        {col.type === 'volume' && (
          <VolumeUnitPicker
            value={columnMappings[col.name]?.unit}
            setvolumeunit={(unit) => handleUnitChange(col.name, unit, 'volume')}
          />
        )}
        {col.type === 'length' && (
        <LengthUnitPicker
          lengthunit={columnMappings[col.name]?.unit}
          setlengthunit={(unit) => handleUnitChange(col.name, unit, 'length')}
        />
        )}
      </Box>
      {isMultiple && (
        <Typography variant="caption">
          {col.name === 'ivtContainerId'
            ? t('barrel_inventory.ivtContainerIdCaption')
            : t('barrel_inventory.ivtContainerDisplayInfoCaption')}
        </Typography>
      )}
      {col.name === 'pureAlcoholTheoretical' && (
        <Typography variant="caption">
          {t('barrel_inventory.info_theoretical_ap')}
        </Typography>
      )}
    </Box>
  );
}

function GroupHeader({ text, isMultiple, handleClose }) {
  const { t } = useTranslation();

  const StyledGroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    backgroundColor:
      lighten(theme.palette.primary.light, 0.75),
  }));

  if (!isMultiple) {
    return <StyledGroupHeader>{text}</StyledGroupHeader>;
  }
  return (
    <StyledGroupHeader>
      <Button
        variant="text"
        fullWidth
        onClick={handleClose}
      >
        {t('close')}
      </Button>
    </StyledGroupHeader>
  );
}
