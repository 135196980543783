import { addDoc, collection } from 'firebase/firestore';
import { createNewFilename } from './createNewFilename';

const applySorting = (data, sortModel) => {
  const dataCopy = [...data]; // Create a shallow copy of the array
  return dataCopy.sort((a, b) => {
    for (const sort of sortModel) {
      if (a[sort.field] < b[sort.field]) {
        return sort.sort === 'asc' ? -1 : 1;
      }
      if (a[sort.field] > b[sort.field]) {
        return sort.sort === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });
};

const applyFiltering = (data, filterModel) => data.filter((row) => filterModel.items.every((filter) => {
  if (filter.value === undefined) {
    return true;
  }
  const fieldValue = row[filter.field];
  switch (filter.operator) {
    case 'contains':
      return fieldValue.includes(filter.value);
    case 'equals':
      return fieldValue === filter.value;
    case 'startsWith':
      return fieldValue.startsWith(filter.value);
    case 'endsWith':
      return fieldValue.endsWith(filter.value);
    case 'isEmpty':
      return fieldValue === '';
    case 'isNotEmpty':
      return fieldValue !== '';
    case 'isAnyOf':
      return filter.value.includes(fieldValue);
    default:
      return true;
  }
}));

export default async function uploadInventory(
  inventoryName,
  setStatus,
  globalActions,
  t,
  navigate,
  containersArray,
  sortModel,
  filterModel,
  columnMappings,
  csvHeaders,
  fileName,
  checkInventoryExists,
  firestore,
  globalState,
  decimalSeparator,
) {
  if (!inventoryName || !inventoryName.trim()) {
    globalActions.setSnackbarMessage({ message: `${t('create_inventory.name_required')}`, severity: 'warning' });
    setStatus('initial');
    return;
  }
  try {
    setStatus('loading');
    const sortedData = applySorting(containersArray, sortModel);
    const filteredSortedData = applyFiltering(sortedData, filterModel);
    // Check if inventory with the same name already exists
    const inventoryExists = await checkInventoryExists(inventoryName);
    if (inventoryExists) {
      globalActions.setSnackbarMessage({
        message: `${t('create_inventory.already_exists')}, ${t('create_inventory.name_required')}`,
        severity: 'error',
      });
      setStatus('initial');
      return;
    }

    const newInventoryDoc = {
      timestamp: new Date(),
      containersArray: filteredSortedData,
      columnMappings,
      name: inventoryName,
      status: 'initial',
      originalDataColumnsOrder: csvHeaders,
      fileName: createNewFilename(fileName),
      decimalSeparator,
    };

    await addDoc(
      collection(firestore, `/organizations/${globalState.activeOrganization}/apps/inventory/inventories`),
      newInventoryDoc,
    );
    globalActions
      .setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    navigate(-1);
  } catch (err) {
    globalActions
      .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
    throw err;
  } finally {
    setStatus('initial');
  }
}
