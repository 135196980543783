import * as XLSX from 'xlsx';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import Papa from 'papaparse';
import { calculateTotalAP, calculateTotalEvaporation } from './runConversions';

export function formatNumber(value, column, inventory, asText) {
  const notNullValue = value || '';

  if (asText) {
    const noNumberColumns = ['ivtContainerId', 'ivtContainerDisplayInfo', 'filling'];
    const mappedNumberColumn = Object.entries(inventory.columnMappings)
      .filter(([name, val]) => !noNumberColumns.includes(name) && val.csvName === column)
      .map(([key, val]) => val.csvName);

    const definedDecimalsSeparator = inventory.decimalSeparator || ',';
    if (mappedNumberColumn && definedDecimalsSeparator === ',') {
      return notNullValue.toString().replace('.', ',');
    }
  }
  return notNullValue;
}

const excludeColumns = ['id', 'abvStatus', 'volumeStatus'];

const getOrderdFilteredData = (inventory, columnOrder, asText = false) => {
  const allColumns = Object.keys(inventory.containersArray[0] || {});

  if (columnOrder) {
    const extraColumns = allColumns.filter((col) => !columnOrder.includes(col));
    const finalColumnOrder = [...columnOrder, ...extraColumns];
    allColumns.sort((a, b) => finalColumnOrder.indexOf(a) - finalColumnOrder.indexOf(b));
  }

  const filteredColumns = allColumns
    .filter((col) => !excludeColumns.includes(col));

  return inventory.containersArray.map((row) => {
    const newRow = {};
    filteredColumns.forEach((column) => {
      newRow[column] = formatNumber(
        row[column],
        column,
        inventory,
        asText,
      );
    });
    return newRow;
  });
};

function getAdditionalData(inventory, t) {
  const evaporationResults = calculateTotalEvaporation(inventory.containersArray, inventory.columnMappings);

  const apResults = calculateTotalAP(inventory.containersArray, inventory.columnMappings);
  return [{
    [t('total_evaporation_info')]:
        `${evaporationResults.totalEvaporation || ''} ${dynamicUnitName(evaporationResults.unit) || ''}`,
    [t('total_percent_evaporation_info')]: `${evaporationResults.totalPercentEvaporation || ''} %`,
    [t('total_ap_info')]:
      `${apResults.totalAP || ''} ${dynamicUnitName(apResults.unit) || ''}`,
  }];
}

function getAdditionalDataForMultipleInventories(inventories, t, commonColumnOrder) {
  const allAdditionalData = [];

  inventories.forEach((inventory) => {
    const evaporationResults = calculateTotalEvaporation(inventory.containersArray, inventory.columnMappings);
    const apResults = calculateTotalAP(inventory.containersArray, inventory.columnMappings);

    allAdditionalData.push({
      [t('name')]: inventory.fileName,
      [t('total_evaporation_info')]:
            `${evaporationResults.totalEvaporation || ''} ${dynamicUnitName(evaporationResults.unit) || ''}`,
      [t('total_percent_evaporation_info')]: `${evaporationResults.totalPercentEvaporation || ''} %`,
      [t('total_ap_info')]:
          `${apResults.totalAP || ''} ${dynamicUnitName(apResults.unit) || ''}`,
    });
  });

  return allAdditionalData;
}

const getCommonColumnOrder = (inventories) => {
  if (inventories.length === 0) return [];
  const allColumns = new Set();

  // Collect all unique columns from all inventories
  inventories.forEach((inventory) => {
    const invAllColumns = Object.keys(inventory.containersArray[0] || {});
    invAllColumns.forEach((column) => {
      allColumns.add(column);
    });
  });

  // Collect all column orders
  const allColumnOrders = inventories.map((inventory) => inventory.originalDataColumnsOrder || []);

  // Identify common columns
  const commonColumns = allColumnOrders.reduce((acc, columns) => acc.filter(
    (col) => columns.includes(col),
  ), allColumnOrders[0]);

  const extraColumns = Array.from(allColumns).filter((col) => !commonColumns.includes(col));

  const lastColumnsOrder = inventories.reduce((acc, inventory) => {
    const rowWithMostHeaders = inventory.containersArray[0] || {};
    const order = [
      inventory.columnMappings.usableCapacity?.csvName,
      inventory.columnMappings.filling?.csvName,
      inventory.columnMappings.type?.csvName,
      inventory.columnMappings.liquidHeight?.csvName,
      inventory.columnMappings.emptyHeight?.csvName,
      inventory.columnMappings.liquidVolume?.csvName,
      inventory.columnMappings.tav?.csvName,
      inventory.columnMappings.tavTemperature?.csvName,
      inventory.columnMappings.volumeTemperature?.csvName,
      inventory.columnMappings.ABVAt20cResult?.csvName,
      inventory.columnMappings.volumeCorrectionFactorResult?.csvName,
      inventory.columnMappings.liquidVolumeResult?.csvName,
      inventory.columnMappings.volumeAt20cResult?.csvName,
      inventory.columnMappings.pureAlcoholVolumeAt20cResult?.csvName,
      inventory.columnMappings.evaporation?.csvName,
      inventory.columnMappings.percentEvaporation?.csvName,
    ].filter((column) => column !== undefined && column in rowWithMostHeaders);
    return [...acc, ...order];
  }, []);

  const uniqueLastColumnsOrder = [...new Set(lastColumnsOrder)];

  const sortedExtraColumns = extraColumns.sort((a, b) => {
    const indexA = uniqueLastColumnsOrder.indexOf(a);
    const indexB = uniqueLastColumnsOrder.indexOf(b);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  // Determine common order
  const commonColumnOrder = [...commonColumns, ...sortedExtraColumns];

  return commonColumnOrder;
};

export const downloadAsXlsx = (
  inventory,
  fileName,
  callback,
  t,
  dataToExport = getOrderdFilteredData(inventory, inventory.originalDataColumnsOrder),
) => {
  const containers = dataToExport;
  const worksheet1 = XLSX.utils.json_to_sheet(containers);

  const worksheet2Data = getAdditionalData(inventory, t);
  const worksheet2 = XLSX.utils.json_to_sheet(worksheet2Data);

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet1, 'export_labox');
  XLSX.utils.book_append_sheet(workbook, worksheet2, 'export_labox_2');

  XLSX.writeFile(workbook, `${fileName}.xlsx`);

  callback();
};

export const downloadInventoriesAsXlsx = (inventories, fileName, callback, t) => {
  const workbook = XLSX.utils.book_new();
  let allContainers = [];

  const commonColumnOrder = getCommonColumnOrder(inventories);

  inventories.forEach((inventory) => {
    const dataToExport = getOrderdFilteredData(inventory, commonColumnOrder);
    allContainers = allContainers.concat(dataToExport);
  });

  const worksheet1 = XLSX.utils.json_to_sheet(allContainers);
  const allAdditionalData = getAdditionalDataForMultipleInventories(inventories, t, commonColumnOrder);
  const worksheet2 = XLSX.utils.json_to_sheet(allAdditionalData);

  XLSX.utils.book_append_sheet(workbook, worksheet1, 'export_labox');
  XLSX.utils.book_append_sheet(workbook, worksheet2, 'export_labox_2');

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
  callback();
};

export const downloadAsCsv = (
  inventory,
  fileName,
  callback,
  t,
  dataToExport = getOrderdFilteredData(inventory, inventory.originalDataColumnsOrder, true),
) => {
  // const containers = dataToExport;
  // const worksheet1 = XLSX.utils.json_to_sheet(containers);
  // const worksheet2Data = getAdditionalData(inventory, t);
  // const worksheet2 = XLSX.utils.json_to_sheet(worksheet2Data);

  // const csv1 = XLSX.utils.sheet_to_csv(worksheet1);
  // const csv2 = XLSX.utils.sheet_to_csv(worksheet2);

  const containers = dataToExport;
  const worksheet2Data = getAdditionalData(inventory, t);

  const csv1 = Papa.unparse(containers, { dynamicTyping: true });
  const csv2 = Papa.unparse(worksheet2Data, { dynamicTyping: true });

  downloadCsv(csv1, fileName);
  downloadCsv(csv2, `${fileName}_2`);
  callback();
};

export const downloadInventoriesAsCsv = (inventories, fileName, callback, t) => {
  let allContainers = [];

  const commonColumnOrder = getCommonColumnOrder(inventories);

  inventories.forEach((inventory) => {
    const dataToExport = getOrderdFilteredData(inventory, commonColumnOrder, true);
    allContainers = allContainers.concat(dataToExport);
  });

  const worksheet1 = XLSX.utils.json_to_sheet(allContainers);
  const allAdditionalData = getAdditionalDataForMultipleInventories(inventories, t);
  const worksheet2 = XLSX.utils.json_to_sheet(allAdditionalData);

  const csv1 = XLSX.utils.sheet_to_csv(worksheet1);
  const csv2 = XLSX.utils.sheet_to_csv(worksheet2);

  downloadCsv(csv1, fileName);
  downloadCsv(csv2, `${fileName}_2`);
  callback();
};

function downloadCsv(csv, name) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}
