import React, {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid, GridRowsProp, GridColDef, useGridApiRef,
} from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import {
  Box, Button,
} from '@mui/material';
import useGlobal from 'global-state/store';
import UndoIcon from '@mui/icons-material/Undo';
import { getKeyByNestedProperty, userInventoryAppAccess } from 'components/utils';
import CustomEditCell from './CustomEditCell';
import InventoryGridToolbar from './InventoryGridToolbar';
import { StatusCellEditor, StatusCellRenderer } from './StatusCellRenderer';
import ExportButton from '../inventories/ExportButton';

export default function InventoryGrid({
  rows, setRows, columnMappings, editable = true, onRowsSelected, addLineAction = false,
  onFilteringChange = () => {}, onSortingChange = () => {}, originalDataColumnsOrder,
  onRowsChange = () => {}, inventory,
}) {
  const { t } = useTranslation();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [sortModel, setSortModel] = useState([]);
  const [globalState, globalActions] = useGlobal();
  const [editFieldForSelectedLines, setEditFieldForSelectedLines] = useState(false);
  const [changeHistory, setChangeHistory] = useState([]);
  const gridRef = useGridApiRef();

  const onFilterModelChange = (model) => {
    onFilteringChange(model);
    setFilterModel(model);
  };
  const onSortModelChange = (model) => {
    onSortingChange(model);
    setSortModel(model);
  };

  const rowWithMostHeaders = rows.reduce((prev, current) => (
    (Object.keys(current).length > Object.keys(prev).length) ? current : prev), rows[0] || {});

  const handleProcessRowUpdateError = (err) => {
    globalActions
      .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
    throw err;
  };

  const handleUndo = () => {
    const lastChange = changeHistory.pop();
    setChangeHistory(changeHistory);

    if (lastChange) {
      // Map to quickly find old rows
      const oldRowsMap = new Map(lastChange.rows.map((row) => [row.id, row]));

      // Merge current rows with old rows, maintaining the original order
      const revertedRows = rows.map((row) => oldRowsMap.get(row.id) || row);

      // Add back any rows that were removed
      lastChange.rows.forEach((oldRow) => {
        if (!revertedRows.find((r) => r.id === oldRow.id)) {
          revertedRows.push(oldRow);
        }
      });

      setRows(revertedRows);
      onRowsChange(revertedRows);
    }
  };

  const handleRowUpdate = (updatedRow, originalRow) => {
    let changedField = null;
    for (const key in updatedRow) {
      if (updatedRow[key] !== originalRow[key]) {
        changedField = key;
        break;
      }
    }
    if (changedField) {
      let updatedRows;
      let oldRowsSnapshot;
      if (editFieldForSelectedLines) {
        oldRowsSnapshot = rows.filter((row) => selectedRowIds.includes(row.id));
        updatedRows = rows.map((row) => {
          if (selectedRowIds.includes(row.id)) {
            return { ...row, [changedField]: updatedRow[changedField] };
          }
          return row;
        });
      } else {
        updatedRows = rows.map((row) => {
          if (row.id === updatedRow.id) {
            return updatedRow;
          }
          return row;
        });
        oldRowsSnapshot = [originalRow];
      }
      setRows(updatedRows);
      onRowsChange(updatedRows);
      setChangeHistory((prev) => [...prev, { rows: oldRowsSnapshot }]);
    }
    setEditFieldForSelectedLines(false);
    return updatedRow;
  };

  const handleKeepOnlyRows = () => {
    const removedRows = rows.filter((row) => !selectedRowIds.includes(row.id));
    const updatedRows = rows.filter((row) => selectedRowIds.includes(row.id));
    setRows(updatedRows);
    onRowsChange(updatedRows);
    setChangeHistory((prev) => [...prev, { rows: removedRows }]);
  };

  const handleDeleteRows = () => {
    const removedRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const updatedRows = rows.filter((row) => !selectedRowIds.includes(row.id));
    setRows(updatedRows);
    onRowsChange(updatedRows);
    setChangeHistory((prev) => [...prev, { rows: removedRows }]);
  };

  const handleAddRow = () => {
    const newRow = {
      id: rows.length > 0 ? Math.max(...rows.map((row) => row.id)) + 1 : 1,
      ...Object.fromEntries(Object.keys(rowWithMostHeaders).map((key) => [key, ''])),
    };
    setRows([...rows, newRow]);
    onRowsChange([...rows, newRow]);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectedRowIds(newSelectionModel);
    if (onRowsSelected) {
      onRowsSelected(newSelectionModel);
    }
  };

  const volumeStatusColumn = {
    field: 'volumeStatus',
    headerName: t('inventory_item.status_label.volumeStatus'),
    width: 120,
    editable: true,
    renderCell: (params) => <StatusCellRenderer params={params} t={t} />,
    renderEditCell: (params) => <StatusCellEditor params={params} t={t} />,
  };

  const abvStatusColumn = {
    field: 'abvStatus',
    headerName: t('inventory_item.status_label.abvStatus'),
    width: 120,
    editable: true,
    renderCell: (params) => <StatusCellRenderer params={params} t={t} />,
    renderEditCell: (params) => <StatusCellEditor params={params} t={t} />,
  };

  const firstColumnsOrder = userInventoryAppAccess(globalState.accessGrantNames) === 'full' ? [
    'abvStatus',
    'volumeStatus',
  ] : [];

  const lastColumnsOrder = [
    columnMappings.usableCapacity?.csvName,
    columnMappings.filling?.csvName,
    columnMappings.type?.csvName,
    columnMappings.liquidHeight?.csvName,
    columnMappings.emptyHeight?.csvName,
    columnMappings.liquidVolume?.csvName,
    columnMappings.tav?.csvName,
    columnMappings.tavTemperature?.csvName,
    columnMappings.volumeTemperature?.csvName,
    columnMappings.ABVAt20cResult?.csvName,
    columnMappings.volumeCorrectionFactorResult?.csvName,
    columnMappings.liquidVolumeResult?.csvName,
    columnMappings.volumeAt20cResult?.csvName,
    columnMappings.pureAlcoholVolumeAt20cResult?.csvName,
    t('create_inventory.default_mapping_fields.volumeUnit'),
    columnMappings.evaporation?.csvName,
    columnMappings.percentEvaporation?.csvName,
  ].filter((column) => column !== undefined && column in rowWithMostHeaders);

  const orderedColumns = (() => {
    const middleOrder = originalDataColumnsOrder || Object.keys(rowWithMostHeaders);
    let finalColumns = [...firstColumnsOrder, ...middleOrder, ...lastColumnsOrder];

    // Remove duplicates while preserving the order from originalDataColumnsOrder
    finalColumns = finalColumns.filter((column, index, self) => index === self.indexOf(column));

    finalColumns = finalColumns.filter((column) => column !== 'id');

    return finalColumns;
  })();

  const createColumnDefinition = (key) => {
    let columnWidth;
    const isEditable = editable;
    let customColumn;

    // Handle custom columns like ivtContainerIdColumn or statusColumn
    if (key === 'abvStatus') {
      customColumn = abvStatusColumn;
    }
    if (key === 'volumeStatus') {
      customColumn = volumeStatusColumn;
    }

    // Set column width (customize as needed)
    switch (key) {
      case columnMappings.heightUnderBung.csvName:
      case columnMappings.totalCapacity.csvName:
      case columnMappings.liquidHeight?.csvName:
      case columnMappings.emptyHeight?.csvName:
      case columnMappings.tav?.csvName:
      case columnMappings.volumeTemperature?.csvName:
      case columnMappings.liquidVolume?.csvName:
      case columnMappings.ABVAt20cResult?.csvName:
      case columnMappings.pureAlcoholVolumeAt20cResult?.csvName:
      case columnMappings.liquidVolumeResult?.csvName:
      case columnMappings.volumeAt20cResult?.csvName:
      case columnMappings.volumeCorrectionFactorResult?.csvName:
      case t('create_inventory.default_mapping_fields.volumeUnit'):
      case columnMappings.evaporation?.csvName:
      case columnMappings.percentEvaporation?.csvName:
        columnWidth = 150;
        break;
      default:
        columnWidth = 110;
    }

    const option = columnMappings[getKeyByNestedProperty(columnMappings, 'csvName', key)];

    // Return the custom column if defined, otherwise return standard column definition
    return customColumn || {
      field: key,
      headerName: key,
      width: columnWidth,
      editable: key !== 'id' && isEditable,
      valueGetter: (value, row, column, apiRef) => row[key],
      type: option?.cellType || 'string',
      renderEditCell: (params) => (
        <CustomEditCell
          id={params.id}
          field={params.field}
          value={params.value}
          hasFocus={params.hasFocus}
          colDef={params.colDef}
          editFieldForSelectedLines={editFieldForSelectedLines}
          setEditFieldForSelectedLines={setEditFieldForSelectedLines}
        />
      ),
    };
  };

  const columns = orderedColumns.map(createColumnDefinition);

  const isHighlightedRow = (row) => {
    const paValue = row[columnMappings.pureAlcoholVolumeAt20cResult.csvName];
    const isValid = paValue !== null && paValue !== undefined && paValue !== '';
    return isValid;
  };

  const gridRows: GridRowsProp = rows;
  const gridColumns: GridColDef[] = columns;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      width: '100%',
      textAlign: 'left',
    }}
    >
      <DataGrid
        autoHeight
        apiRef={gridRef}
        rowHeight={25}
        localeText={t('locale') === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
        rows={gridRows}
        columns={gridColumns}
        pageSize={5}
        checkboxSelection={editable || onRowsSelected !== undefined}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        processRowUpdate={handleRowUpdate}
        slots={{
          toolbar: InventoryGridToolbar,
        }}
        onRowSelectionModelChange={handleSelectionModelChange}
        selectionModel={selectedRowIds}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        disableRowSelectionOnClick
        getRowClassName={(params) => {
          const isHighlight = isHighlightedRow(params.row);
          return isHighlight ? 'highlightedRow' : '';
        }}
        sx={{
          '& .highlightedRow': {
            backgroundColor: '#99bd5b26',
            '&:hover': {
              backgroundColor: '#bbc9a399',
            },
          },
          '& .Mui-selected.highlightedRow': {
            backgroundColor: '#99bd5b99',
            '&:hover': {
              backgroundColor: '#bbc9a399',
            },
          },
        }}
      />

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        gap: 1,
      }}
      >
        {inventory && (
          <ExportButton inventory={inventory} gridRef={gridRef} />
        )}

        {editable && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            gap: 1,
          }}
          >
            {addLineAction && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleAddRow}
            >
              {t('inventory_grid.add_line')}
            </Button>
            )}

            <Button
              size="small"
              variant="outlined"
              onClick={handleDeleteRows}
            >
              {t('inventory_grid.delete_lines')}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleKeepOnlyRows}
            >
              {t('inventory_grid.keep_only_lines')}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleUndo}
              startIcon={<UndoIcon />}
            >
              {t('undo')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
