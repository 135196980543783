import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usableCapacityColumnOptions } from '../columnsUtils';
import AutocompleteAndUnitSelect from './AutocompleteAndUnitSelect';

function UsableCapacityAutocomplete({
  columnMappings,
  handleMappingChange,
  validCsvHeaders,
  isCsvHeaderValid,
  handleUnitChange,
  columnOptions,
  updateColumnOptions,
}) {
  const col = useMemo(() => usableCapacityColumnOptions(columnOptions), [columnOptions]);
  const { t } = useTranslation();
  const [showCompletion, setShowCompletion] = useState(false);

  useEffect(() => {
    const defaultValue = columnMappings[col.name]?.csvName;
    if (!validCsvHeaders.includes(defaultValue)) {
      setShowCompletion(true);
      updateColumnOptions(col.name, {
        ...col,
        toFill: true,
      });
    }
  }, [col, columnMappings, updateColumnOptions, validCsvHeaders]);

  const handleMappingChangeWrap = (colName, colType, newInputValue) => {
    if (!validCsvHeaders.includes(newInputValue)) {
      setShowCompletion(true);
      updateColumnOptions(col.name, {
        ...col,
        toFill: true,
      });
    } else {
      setShowCompletion(false);
      updateColumnOptions(col.name, {
        ...col,
        toFill: false,
      });
    }
    handleMappingChange(col.name, col.type, newInputValue);
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
      <AutocompleteAndUnitSelect
        key={col.name}
        col={col}
        columnMappings={columnMappings}
        handleMappingChange={handleMappingChangeWrap}
        validCsvHeaders={validCsvHeaders}
        isCsvHeaderValid={isCsvHeaderValid}
        handleUnitChange={handleUnitChange}
      />
      {showCompletion && (
        <Typography variant="caption" sx={{ color: 'orange' }}>
          {t('usable_capacity_completion')}
        </Typography>
      )}
    </Box>
  );
}

export default UsableCapacityAutocomplete;
