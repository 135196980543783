import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, Paper, List, ListItem,
  Button, FormControl, InputLabel, Select, MenuItem, Pagination, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import ClearIcon from '@mui/icons-material/Clear';
import InventoryItem from './InventoryItem';

const getContainerId = (item, ivtContainerIdMapping) => {
  if (Array.isArray(ivtContainerIdMapping)) {
    return ivtContainerIdMapping.map((colName) => item[colName]).join(' / ');
  }
  return item[ivtContainerIdMapping];
};

export default function InventoryItems({ inventoryDetails, setInventoryDetails }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [filterInput, setFilterInput] = useState('');
  const [fillingStatusFilter, setFillingStatusFilter] = useState('');

  useEffect(() => {
    if (inventoryDetails && inventoryDetails.containersArray) {
      setTotalPages(Math.ceil(inventoryDetails.containersArray.length / rowsPerPage));
    }
  }, [inventoryDetails, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when changing rows per page
  };

  const getMatchString = (item, field) => {
    if (Array.isArray(field)) {
      return field.map((f) => item[f]).join(' / ');
    }
    return item[field];
  };

  const itemsToDisplay = inventoryDetails.containersArray
    .filter((item) => {
      const ivtContainerId = getMatchString(item, inventoryDetails.columnMappings.ivtContainerId.csvName);
      const ivtContainerDisplayInfo = getMatchString(
        item,
        inventoryDetails.columnMappings.ivtContainerDisplayInfo.csvName,
      );
      const fillingStatusValue = fillingStatusFromValue(item[inventoryDetails.columnMappings.filling.csvName]);
      const searchText = filterInput.toLowerCase();

      return (fillingStatusFilter === '' || fillingStatusFilter === fillingStatusValue)
        && (ivtContainerId.toLowerCase().includes(searchText)
        || ivtContainerDisplayInfo.toLowerCase().includes(searchText));
    })
    .slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const ivtContainerIdKey = Array.isArray(inventoryDetails.columnMappings.ivtContainerId.csvName)
    ? inventoryDetails.columnMappings.ivtContainerId.csvName.join(' / ')
    : inventoryDetails.columnMappings.ivtContainerId.csvName;

  const [sortOrder, setSortOrder] = useState({
    [ivtContainerIdKey]: 'asc',
    abvStatus: 'asc',
    volumeStatus: 'asc',
  });

  const handleReinitFiltersAndSorting = () => {
    setFilterInput('');
    setFillingStatusFilter('');
    setSortOrder({
      [ivtContainerIdKey]: 'asc',
      abvStatus: 'asc',
      volumeStatus: 'asc',
    });
  };

  const getContainerUrlPathReference = (item) => {
    const ivtContainerIdMapping = inventoryDetails.columnMappings.ivtContainerId.csvName;
    if (Array.isArray(ivtContainerIdMapping)) {
      return ivtContainerIdMapping.map((colName) => item[colName]).join('_');
    }
    return item[ivtContainerIdMapping];
  };

  const onItemClick = (itemData) => {
    navigate(`operation/${getContainerUrlPathReference(itemData)}`, {
      state: {
        inventoryData: inventoryDetails,
        containerId: itemData.id,
      },
    });
  };

  const sortData = (field) => {
    const order = sortOrder[field] === 'asc' ? 'desc' : 'asc';
    const sortedData = [...inventoryDetails.containersArray].sort((a, b) => {
      let aValue; let
        bValue;

      if (Array.isArray(field)) {
        aValue = field.map((f) => a[f]).join(' / ');
        bValue = field.map((f) => b[f]).join(' / ');
      } else {
        aValue = a[field];
        bValue = b[field];
      }

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });

    setInventoryDetails({ ...inventoryDetails, containersArray: sortedData });
    setSortOrder({ ...sortOrder, [field]: order });
  };

  const ivtContainerIdLabel = () => {
    const { csvName } = inventoryDetails.columnMappings.ivtContainerId;
    if (Array.isArray(csvName)) {
      return csvName.join(' / ');
    }
    return csvName;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex', flexDirection: 'column', marginBottom: 2, gap: 1, p: 1,
        }}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', padding: 1, gap: 1,
        }}
        >
          <Typography variant="h5">{inventoryDetails.name}</Typography>
          <Typography variant="subtitle1">
            Status:
            {' '}
            {t(`inventory_detail.status.${inventoryDetails.status}`)}
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
          >
            <TextField
              label={t('operation.filter_by_name')}
              variant="outlined"
              value={filterInput}
              onChange={(e) => setFilterInput(e.target.value)}
              size="small"
              sx={{ width: '100%', maxWidth: 200 }}
            />
            <FormControl size="small" sx={{ width: '100%', maxWidth: 200 }}>
              <InputLabel id="filling-status-label">{t('barrel_inventory.column_options.filling')}</InputLabel>
              <Select
                labelId="filling-status-label"
                id="filling-status-select"
                value={fillingStatusFilter}
                label={t('barrel_inventory.column_options.filling')}
                onChange={(e) => setFillingStatusFilter(e.target.value)}
              >
                <MenuItem value="">
                  <em>{t('all')}</em>
                </MenuItem>
                <MenuItem value="draining">{t('inventory_item.filling.draining')}</MenuItem>
                <MenuItem value="empty">{t('inventory_item.filling.empty')}</MenuItem>
                <MenuItem value="full">{t('inventory_item.filling.full')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 1,
          alignItems: 'stretch',
        }}
        >
          <Button
            variant="outlined"
            sx={{
              minWidth: '30px',
              fontSize: '0.65rem',
            }}
            size="small"
            onClick={() => sortData(inventoryDetails.columnMappings.ivtContainerId.csvName)}
          >
            {ivtContainerIdLabel()}
            {sortOrder[inventoryDetails.columnMappings.ivtContainerId.csvName] === 'asc'
              ? <ArrowUpwardIcon sx={{ fontSize: '16px' }} /> : <ArrowDownwardIcon sx={{ fontSize: '16px' }} />}
          </Button>
          <Box sx={{
            display: 'flex',
            gap: 1,
          }}
          >
            <Button
              variant="outlined"
              sx={{
                minWidth: '30px',
                fontSize: '0.65rem',
              }}
              size="small"
              onClick={() => sortData('abvStatus')}
            >
              {t('inventory_item.status_label.abvStatus')}
              {sortOrder.abvStatus === 'asc'
                ? <ArrowUpwardIcon sx={{ fontSize: '16px' }} /> : <ArrowDownwardIcon sx={{ fontSize: '16px' }} />}
            </Button>
            <Button
              variant="outlined"
              sx={{
                minWidth: '30px',
                fontSize: '0.65rem',
              }}
              size="small"
              onClick={() => sortData('volumeStatus')}
            >
              {t('inventory_item.status_label.volumeStatus')}
              {sortOrder.volumeStatus === 'asc'
                ? <ArrowUpwardIcon sx={{ fontSize: '16px' }} /> : <ArrowDownwardIcon sx={{ fontSize: '16px' }} />}
            </Button>
          </Box>
        </Box>
        <Button
          variant="outlined"
          sx={{
            minWidth: '30px',
            fontSize: '0.65rem',
            alignSelf: { xs: 'center', sm: 'flex-end' },
            width: { xs: '100%', sm: 'auto' },
          }}
          size="small"
          onClick={handleReinitFiltersAndSorting}
          endIcon={<ClearIcon />}
        >
          {t('reset')}
        </Button>
      </Paper>
      <List>
        {itemsToDisplay.map((item, index) => {
          const containerId = getContainerId(item, inventoryDetails.columnMappings.ivtContainerId.csvName);
          const { abvStatus, volumeStatus } = item;
          return (
            <ListItem
              key={containerId}
              sx={{ backgroundColor: index % 2 === 0 ? 'lightgray' : 'transparent' }}
            >
              <InventoryItem
                containerABVStatus={abvStatus}
                containerVolumeStatus={volumeStatus}
                itemData={item}
                columnMappings={inventoryDetails.columnMappings}
                onClick={onItemClick}
              />
            </ListItem>
          );
        })}
      </List>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 2, flexWrap: 'wrap',
      }}
      >
        <FormControl size="small">
          <InputLabel id="rows-per-page-label">{t('pagination.lines_per_page')}</InputLabel>
          <Select
            sx={{ width: 75 }}
            labelId="rows-per-page-label"
            id="rows-per-page"
            value={rowsPerPage}
            label={t('pagination.lines_per_page')}
            onChange={handleRowsPerPageChange}
          >
            {[10, 25, 50, 100].map((num) => (
              <MenuItem key={num} value={num}>{num}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          size="small"
          variant="outlined"
          shape="rounded"
          siblingCount={0}
          boundaryCount={1}
        />
      </Box>
    </Box>
  );
}
