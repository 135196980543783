import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

export default function InventoryListItem(props) {
  const {
    index, handleOpen, inventory, isSelected, handleCheckInventory, displayStatuses = true,
  } = props;
  const { t } = useTranslation();

  const getDateString = (timestamp) => (timestamp ? new Date(timestamp.toMillis()).toLocaleString() : 'N/A');

  const customCheckbox = (status) => {
    if (status === 'readyForReview' || status === 'done') {
      return (
        <TaskAltIcon sx={{ color: 'flashyGreen.main' }} />
      );
    }
    if (status === 'locked') {
      return (
        <LockIcon sx={{ color: 'ourOrange.main' }} />
      );
    }
    return (
      <RadioButtonUncheckedIcon />
    );
  };

  return (
    <ListItem key={index} component="div">
      <Checkbox checked={isSelected} onChange={() => handleCheckInventory(inventory.id, !isSelected)} />
      <ListItemButton sx={{ py: 1 }} onClick={() => handleOpen(inventory)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >

          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>{inventory.name}</Typography>
          <Box sx={{
            display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap',
          }}
          >
            {customCheckbox(inventory.status)}
            <Typography sx={{ width: 150 }}>
              {t(`inventory_detail.status.${inventory.status}`)}
            </Typography>
            {displayStatuses && (
              <ProgressBarWithLabel
                value={Math.floor(inventory.progressABVPercentage || 0)}
                label={t('inventory_item.status_caption_label.abvStatus')}
                fillColor="secondary.main"
              />
            )}
            {displayStatuses && (
              <ProgressBarWithLabel
                value={Math.floor(inventory.progressVolumePercentage || 0)}
                label={t('inventory_item.status_caption_label.volumeStatus')}
                fillColor="ourOrange.main"
              />
            )}
          </Box>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'end' }}>
            {getDateString(inventory.timestamp)}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

function ProgressBarWithLabel({ value, label, fillColor }) {
  return (
    <Box sx={{
      height: '20px',
      width: 60,
      position: 'relative',
      mt: 1,
    }}
    >
      <Typography
        variant="caption"
        sx={{
          color: fillColor,
          fontWeight: 'bold',
          position: 'absolute',
          top: -15,
        }}
      >
        {label}
      </Typography>
      <Box sx={{
        height: '20px',
        width: 60,
        backgroundColor: 'mediumgray.main',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
        overflow: 'hidden',
      }}
      >
        <Box sx={{
          height: '100%',
          backgroundColor: fillColor,
          textAlign: 'center',
          transition: 'width 0.3s ease-in-out',
          width: `${Math.min(Math.max(value, 0), 100)}%`,
        }}
        />
        <Typography
          variant="body2"
          sx={{
            width: '100%',
            textAlign: 'center',
            alignSelf: 'center',
            color: '#fff',
            fontWeight: 'bold',
            position: 'absolute',
          }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
