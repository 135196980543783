import { dynamicUnitRestrictions } from 'components/units/UnitDefinitionsAndMasks';

export default function alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit) {
  return {
    tavMin: dynamicUnitRestrictions('tav_min_', 'AbVol', alcoholUnit),
    tavMax: dynamicUnitRestrictions('tav_max_', 'AbVol', alcoholUnit),
    alcoholUnit,
    tempMin: dynamicUnitRestrictions('temp_min_', 'celsius', temperatureUnit),
    tempMax: dynamicUnitRestrictions('temp_max_', 'celsius', temperatureUnit),
    tempUnit: temperatureUnit,
  };
}
