import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'reactfire';
import { addDoc, collection } from 'firebase/firestore';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { CircularProgress, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';

export default function SendCertificateDialog(props) {
  const { t } = useTranslation();
  const {
    docBlob, docName, setOpen, open,
  } = props;
  const firestore = useFirestore();
  const [success, setSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState('initial');
  const [base64pdf, setbase64pdf] = useState();

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setSuccess(false);
  };

  useEffect(() => {
    function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result.split(',')[1]);
        };
        reader.readAsDataURL(blob);
      });
    }

    async function setBlobToB64(blob) {
      const res = await blobToBase64(blob);
      setbase64pdf(res);
      setLoadingState('done');
    }

    if (loadingState === 'initial' && docBlob) {
      setBlobToB64(docBlob);
    }
  }, [t, loadingState, docBlob]);

  const sendEmailWithAttachment = useCallback(async (values) => {
    try {
      await addDoc(collection(firestore, 'mail'), {
        to: values.recipientEmail,
        message: {
          subject: `Certificat ${docName}`,
          text: `Message de la part de ${values.from}: ${values.emailText}`
          + `\n\nVeuillez trouver ci-joint le certificat ${docName}`
          + '\n\nCe message vous est envoyé par l\'application Labox Inventaire\n\n'
          + '--\nLabox Applications - contact@labox-apps.com',
          attachments: [
            {
              filename: `${docName}.pdf`,
              content: base64pdf,
              encoding: 'base64',
            },
          ],
        },
      });
    } catch (error) {
      console.error('Error adding email document:', error);
    }
  }, [firestore, docName, base64pdf]);

  const validationSchema = Yup.object().shape({
    recipientEmail: Yup.string().required(t('validations.required')),
    emailText: Yup.string(),
    from: Yup.string().required(t('validations.required')),
  });

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleSubmit = async (values) => {
    localStorage.setItem('send_certificate_from', values.from);
    sendEmailWithAttachment(values);
    setSuccess(true);
    await sleep(500);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      recipientEmail: '',
      emailText: '',
      from: localStorage.getItem('send_certificate_from') || '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      {loadingState !== 'done' && (
        <CircularProgress />
      )}
      {loadingState === 'done' && (
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 2,
        }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            {`${t('send_to_address')}:`}
          </Typography>
          <TextField
            disabled={success}
            id="recipientEmail"
            name="recipientEmail"
            label={t('signin.email')}
            value={formik.values.recipientEmail}
            onChange={formik.handleChange}
            error={formik.touched.recipientEmail && formik.errors.recipientEmail}
            helperText={formik.touched.recipientEmail && formik.errors.recipientEmail}
          />
          <TextField
            disabled={success}
            id="emailText"
            name="emailText"
            label={t('contact.message')}
            multiline
            rows={4}
            value={formik.values.emailText}
            onChange={formik.handleChange}
            error={formik.touched.emailText && formik.errors.emailText}
            helperText={formik.touched.emailText && formik.errors.emailText}
          />
          <TextField
            disabled={success}
            id="from"
            name="from"
            label={t('from')}
            value={formik.values.from}
            onChange={formik.handleChange}
            error={formik.touched.from && formik.errors.from}
            helperText={formik.touched.from && formik.errors.from}
          />
        </DialogContent>
        {success && (
        <CheckCircleOutlineIcon sx={{ color: 'ourGreen.main', fontSize: 50, width: '100%' }} />
        )}
        <DialogActions>
          <Button onClick={() => { handleClose(); }}>
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
          >
            {t('contact.send')}
          </LoadingButton>
        </DialogActions>
      </form>
      )}
    </Dialog>
  );
}
