import React from 'react';
import {
  Box, IconButton, Typography,
} from '@mui/material';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function InventoryItem({
  itemData, columnMappings, showFillingLabel = false, onClick, containerABVStatus, containerVolumeStatus,
}) {
  const { t } = useTranslation();
  const statusColor = (status, defaultColorTodo = '#437A89') => {
    switch (status) {
      case 'toDo':
        return defaultColorTodo;
      case 'done':
        return '#1db954';
      default:
        return 'black';
    }
  };
  const customCheckbox = (status) => {
    if (status === 'done') {
      return (
        <TaskAltIcon sx={{ fontSize: 18, color: 'flashyGreen.main' }} />
      );
    }
    return (
      <RadioButtonUncheckedIcon sx={{ fontSize: 18 }} />
    );
  };

  const multiColumnFieldDisplay = (mappingFields) => {
    let display;

    if (Array.isArray(mappingFields)) {
      display = mappingFields.map((colName) => itemData[colName]).join(' / ');
    } else {
      display = itemData[mappingFields];
    }
    return display;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'default',
        width: '100%',
        flexWrap: 'wrap',
        gap: { xs: 2, sm: 3 },
      }}
      onClick={onClick ? () => onClick(itemData) : undefined}
    >

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexGrow: 1,
      }}
      >

        <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: 200 } }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {multiColumnFieldDisplay(columnMappings.ivtContainerId.csvName)}
          </Typography>
          <Typography variant="caption">
            {multiColumnFieldDisplay(columnMappings.ivtContainerDisplayInfo.csvName)}
          </Typography>
        </Box>
        <Container
          showLabel={showFillingLabel}
          status={fillingStatusFromValue(itemData[columnMappings.filling.csvName])}
        />

        {(containerABVStatus || containerVolumeStatus) && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
          >

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {customCheckbox(containerABVStatus)}
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 'bold', color: statusColor(containerABVStatus, 'primary.main'), width: 28,
                }}
              >
                {t('inventory_item.status_caption_label.abvStatus')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {customCheckbox(containerVolumeStatus)}
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 'bold', color: statusColor(containerVolumeStatus, 'ourOrange.main'), width: 38,
                }}
              >
                {t('inventory_item.status_caption_label.volumeStatus')}
              </Typography>
            </Box>

          </Box>
        )}
      </Box>

      {onClick && (
      <IconButton size="small">
        <ArrowForwardIosIcon />
      </IconButton>
      )}

    </Box>
  );
}

function Container({ status, showLabel = false }) {
  const { t } = useTranslation();
  const getFillingPercentage = () => {
    switch (status) {
      case 'empty':
        return 0;
      case 'draining':
        return 50;
      case 'full':
        return 100;
      default:
        return null;
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case 'empty':
        return 'gray';
      case 'draining':
        return '#f4ad45';
      case 'full':
        return '#f4ad45';
      default:
        return '#gray';
    }
  };

  const containerBoxStyle = {
    width: 25,
    height: 25,
    border: '3px solid gray',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const containerStyle = {
    ...containerBoxStyle,
    // eslint-disable-next-line max-len
    background: `linear-gradient(to top, ${getBorderColor()} ${getFillingPercentage()}%, transparent ${getFillingPercentage()}%)`,
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1,
    }}
    >
      <Box
        sx={containerStyle}
      />
      {showLabel && (
        <Typography sx={{ width: 50, textAlign: 'left' }}>
          {t(`inventory_item.filling.${status}`)}
        </Typography>
      )}
    </Box>

  );
}
