import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

export default function Filters({
  formik, datePickersLabel = '',
  selectedCellarCallback = () => {},
  defaultCellar = false,
}) {
  const { t } = useTranslation();

  const handleStartDateChange = (newValue) => {
    if (!newValue.isSame(formik.values.startDate, 'day')) {
      if (newValue.isAfter(formik.values.endDate, 'day')) {
        formik.setFieldValue('endDate', newValue.clone().endOf('day'));
      }
      formik.setFieldValue('startDate', newValue);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (!newValue.isSame(formik.values.endDate, 'day')) {
      if (newValue.isBefore(formik.values.startDate, 'day')) {
        formik.setFieldValue('startDate', newValue.clone().startOf('day'));
      }
      formik.setFieldValue('endDate', newValue);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        width: '100%',
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="body2">
        {datePickersLabel}
        {' '}
        :
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label={t('admin.between_start_date')}
          inputFormat="DD/MM/YYYY"
          value={formik.values.startDate}
          onChange={handleStartDateChange}
          components={{
            TextField: CustomTextField,
          }}
        />
        <DesktopDatePicker
          label={t('admin.between_end_date')}
          value={formik.values.endDate}
          inputFormat="DD/MM/YYYY"
          onChange={handleEndDateChange}
          components={{
            TextField: CustomTextField,
          }}
        />
      </LocalizationProvider>
      <Button
        disabled={!formik.isValid}
        type="submit"
        size="small"
        variant="contained"
        color="primary"
      >
        {t('apply_filters')}
      </Button>
    </Paper>
  );
}

function CustomTextField(props) {
  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="small"
      sx={{ backgroundColor: 'white' }}
    />
  );
}
