import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import alcoholUnitRelatedsFrom from 'components/units/UnitsRelatedFrom';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import { Button, Paper } from '@mui/material';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMenu from 'menu-actions/useMenu';
import useGlobal from '../../../global-state/store';
import {
  NumberInput,
  LabelInputSelect,
  RadioBoxetteInput,
} from '../BoxetteInputs';
import decimalsMask from '../DecimalsMask';
import validationSchemaLaying from './ValidationSchemaLaying';
import InventoryItem from '../InventoryItem';
import ChangeFillingDialog from './ChangeFillingDialog';
import EditableField from './EditableField';
import { validateFieldsAtSubmit } from './validateFieldsAtSubmit';
import { getDefaultFormValues } from './getDefaultFormValues';

export default function BarrelLayingOperation({
  itemData, columnMappings, recordOperation, nextOperation,
  previousItemFormValues, inventoryData, setInventoryData,
}) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [calculating, setCalculating] = useState(false);
  const fillingStatus = fillingStatusFromValue(itemData[columnMappings.filling.csvName]);
  const isFullOrEmpty = ['full', 'empty'].includes(fillingStatus);
  const isFull = fillingStatus === 'full';
  const isEmpty = fillingStatus === 'empty';
  const { dispatchMenuActions } = useMenu();
  const [openChangeFilling, setOpenChangeFilling] = useState(false);

  const handleNext = () => {
    nextOperation();
  };

  const submitRecord = async (values, proceedToNext) => {
    setCalculating(true);
    try {
      await recordOperation(values, proceedToNext);
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err.message}`, severity: 'warning' });
      throw err;
    } finally {
      setCalculating(false);
    }
  };

  const submit = async (values) => {
    if (await validateFieldsAtSubmit(values, columnMappings, fillingStatus, globalActions, t)) {
      await submitRecord(values, false);
    }
  };

  const handleSubmitAndNext = async () => {
    if (await validateFieldsAtSubmit(formik.values, columnMappings, fillingStatus, globalActions, t)) {
      await submitRecord(formik.values, true);
    }
  };
  const [alcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
    ),
  );

  const formik = useFormik({
    initialValues: getDefaultFormValues(
      t,
      itemData,
      columnMappings,
      globalState,
      previousItemFormValues,
      fillingStatusFromValue,
    ),
    validationSchema: validationSchemaLaying(t, alcoholUnitRelateds),
    onSubmit(values) {
      return submit(values);
    },
  });

  const selectMeasurementType = (setFieldValue, measurementType) => {
    setFieldValue('measurementType', measurementType);
  };

  const handleLiquidHeightChange = (value) => {
    formik.setFieldValue('liquidHeight.value', value, false);
    if (value) {
      formik.setFieldValue('emptyHeight.value', '', false);
      formik.setFieldValue('liquidVolume.value', '', false);
    }
  };

  const handleEmptyHeightChange = (value) => {
    formik.setFieldValue('emptyHeight.value', value, false);
    if (value) {
      formik.setFieldValue('liquidHeight.value', '', false);
      formik.setFieldValue('liquidVolume.value', '', false);
    }
  };

  const handleLiquidVolumeChange = (value) => {
    formik.setFieldValue('liquidVolume.value', value, false);
    if (value) {
      formik.setFieldValue('emptyHeight.value', '', false);
      formik.setFieldValue('liquidHeight.value', '', false);
    }
  };

  const reportChangeFillingAction = useCallback(() => ({
    name: t('operation.modify_filling'),
    callback: () => setOpenChangeFilling(true),
  }), [t]);

  useEffect(() => {
    dispatchMenuActions({ type: 'add', actions: [reportChangeFillingAction()] });
  }, [dispatchMenuActions, reportChangeFillingAction]);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 600,
      }}
    >
      <Paper elevation={0} sx={{ px: 3, py: 2 }}>
        <InventoryItem
          itemData={itemData}
          columnMappings={columnMappings}
          showFillingLabel
          containerABVStatus={itemData.abvStatus}
          containerVolumeStatus={itemData.volumeStatus}
        />
      </Paper>
      <EditableField
        formik={formik}
        fieldKey="totalCapacity"
        translationKey="barrel_inventory.column_options.totalCapacity"
        dynamicUnitName={dynamicUnitName}
        decimalsMask={decimalsMask}
      />
      <EditableField
        formik={formik}
        fieldKey="usableCapacity"
        translationKey="barrel_inventory.column_options.usableCapacity"
        dynamicUnitName={dynamicUnitName}
        decimalsMask={decimalsMask}
      />
      <EditableField
        formik={formik}
        fieldKey="diameterBond"
        translationKey="ullaging.diameter_bond"
        dynamicUnitName={dynamicUnitName}
        decimalsMask={decimalsMask}
      />
      { isFullOrEmpty && (
        <Box sx={{
          display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1,
        }}
        >
          <Typography component="span">{`${t('ullaging.result_volume')} : `}</Typography>
          <Typography component="span">
            {isFull
              ? `${itemData[columnMappings.usableCapacity.csvName]} `
              + `${dynamicUnitName(columnMappings.usableCapacity.unit)} `
              : `0 ${dynamicUnitName(columnMappings.usableCapacity.unit)} `}
            <span style={{ fontWeight: 'bold' }}>
              {t(`inventory_item.filling.${fillingStatus}`)}
            </span>
          </Typography>
        </Box>
      )}

      <Paper
        elevation={0}
        sx={{
          px: { xs: 1, md: 3 },
          py: 2,
          border: 1,
          borderColor: 'secondary.main',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            position: 'absolute',
            top: 2,
            right: 10,
          }}
        >
          Volume
        </Typography>
        { !isFullOrEmpty && (
        <RadioBoxetteInput
          isselected={formik.values.measurementType === 'liquidHeight'}
          onFocused={() => selectMeasurementType(formik.setFieldValue, 'liquidHeight')}
          label={(
            <Typography component="span">
              {t('ullaging.liquid_height')}
            </Typography>
        )}
          input={(
            <NumberInput
              inputcolor={formik.values.measurementType === 'liquidHeight' ? 'white' : '#F2F5F8'}
              id="liquidHeight.value"
              name="liquidHeight.value"
              value={formik.values.liquidHeight.value}
              placeholder="ex: 45.5"
              onValueChangeCallback={handleLiquidHeightChange}
              decimalScale={decimalsMask[formik.values.liquidHeight.unit]}
            />
        )}
          error={
          formik.touched.liquidHeight?.value
          && formik.errors.liquidHeight?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.liquidHeight?.value}
            </Typography>
          )
        }
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.emptyHeight.unit)}
            </Typography>
        )}
        />
        )}

        { !isFullOrEmpty && (
        <RadioBoxetteInput
          isselected={formik.values.measurementType === 'emptyHeight'}
          onFocused={() => selectMeasurementType(formik.setFieldValue, 'emptyHeight')}
          label={
            <Typography component="span">{t('ullaging.empty_height')}</Typography>
        }
          input={(
            <NumberInput
              inputcolor={formik.values.measurementType === 'emptyHeight' ? 'white' : '#F2F5F8'}
              id="emptyHeight.value"
              name="emptyHeight.value"
              value={formik.values.emptyHeight.value}
              placeholder="ex: 20.2"
              onValueChangeCallback={handleEmptyHeightChange}
              decimalScale={decimalsMask[formik.values.emptyHeight.unit]}
            />
        )}
          error={
          formik.touched.emptyHeight?.value
          && formik.errors.emptyHeight?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.emptyHeight?.value}
            </Typography>
          )
        }
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.emptyHeight.unit)}
            </Typography>
        )}
        />
        )}

        { !isFullOrEmpty && (
        <RadioBoxetteInput
          isselected={formik.values.measurementType === 'liquidVolume'}
          onFocused={() => selectMeasurementType(formik.setFieldValue, 'liquidVolume')}
          label={
            <Typography component="span">{t('ullaging.liquid_volume')}</Typography>
        }
          input={(
            <NumberInput
              inputcolor={formik.values.measurementType === 'liquidVolume' ? 'white' : '#F2F5F8'}
              id="liquidVolume.value"
              name="liquidVolume.value"
              value={formik.values.liquidVolume.value}
              placeholder="ex: 20.2"
              onValueChangeCallback={handleLiquidVolumeChange}
              decimalScale={decimalsMask[formik.values.liquidVolume.unit]}
            />
        )}
          error={
          formik.touched.liquidVolume?.value
          && formik.errors.liquidVolume?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.liquidVolume?.value}
            </Typography>
          )
        }
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.liquidVolume.unit)}
            </Typography>
        )}
        />
        )}

        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.liquid_temp')}</Typography>
        }
          input={(
            <NumberInput
              id="volumeTemp.value"
              disabled={isEmpty}
              name="volumeTemp.value"
              onValueChangeCallback={(value) => formik.setFieldValue('volumeTemp.value', value)}
              placeholder="ex: 12.2"
              value={formik.values.volumeTemp.value}
              decimalScale={decimalsMask[formik.values.volumeTemp.unit]}
              allowNegative
            />
        )}
          error={formik.touched.volumeTemp?.value && formik.errors.volumeTemp?.value && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.volumeTemp?.value}
          </Typography>
          )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.volumeTemp.unit)}
            </Typography>
        )}
        />

      </Paper>

      <Paper
        elevation={0}
        sx={{
          px: { xs: 1, md: 3 },
          py: 2,
          border: 1,
          borderColor: 'ourOrange.main',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >

        <Typography
          variant="caption"
          sx={{
            fontWeight: 'bold',
            color: 'ourOrange.main',
            position: 'absolute',
            top: 2,
            right: 10,
          }}
        >
          {t('inventory_item.status_caption_label.abvStatus')}
        </Typography>
        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.measured_tav')}</Typography>
        }
          input={(
            <NumberInput
              id="tav.value"
              disabled={isEmpty}
              onValueChangeCallback={(value) => formik.setFieldValue('tav.value', value)}
              name="tav.value"
              placeholder="ex: 56.88"
              value={formik.values.tav.value}
              decimalScale={decimalsMask[formik.values.tav.unit]}
            />
        )}
          error={formik.touched.tav?.value && formik.errors.tav?.value && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.tav?.value}
          </Typography>
          )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.tav.unit)}
            </Typography>
        )}
        />

        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.measured_temp')}</Typography>
        }
          input={(
            <NumberInput
              id="tavTemp.value"
              disabled={isEmpty}
              name="tavTemp.value"
              onValueChangeCallback={(value) => formik.setFieldValue('tavTemp.value', value)}
              placeholder="ex: 12.2"
              value={formik.values.tavTemp.value}
              decimalScale={decimalsMask[formik.values.tavTemp.unit]}
              allowNegative
            />
        )}
          error={formik.touched.tavTemp?.value && formik.errors.tavTemp?.value && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.tavTemp?.value}
          </Typography>
          )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.tavTemp.unit)}
            </Typography>
        )}
        />
      </Paper>

      {isEmpty && (
      <Button
        onClick={handleSubmitAndNext}
        variant="contained"
        endIcon={<ArrowForwardIosIcon />}
      >
        {t('inventory_item.validate_and_continue')}
      </Button>
      )}
      {isEmpty && (
        <Button
          type="submit"
          variant="contained"
          endIcon={<DoneIcon />}
        >
          {t('validate')}
        </Button>
      )}

      {!isEmpty && (
      <LoadingButton
        variant="contained"
        loading={calculating}
        disabled={!formik.isValid}
        onClick={handleSubmitAndNext}
        endIcon={<ArrowForwardIosIcon />}
      >
        {t('inventory_item.record_and_continue')}
      </LoadingButton>
      )}
      {!isEmpty && (
      <LoadingButton
        variant="contained"
        type="submit"
        loading={calculating}
        disabled={!formik.isValid}
        endIcon={<DoneIcon />}
      >
        {t('validate')}
      </LoadingButton>
      )}

      <Button variant="outlined" onClick={handleNext} endIcon={<ArrowForwardIosIcon />}>
        {t('inventory_item.next_container')}
      </Button>
      <ChangeFillingDialog
        open={openChangeFilling}
        setOpen={setOpenChangeFilling}
        itemData={itemData}
        setInventoryData={setInventoryData}
        inventoryData={inventoryData}
      />
    </Box>
  );
}
