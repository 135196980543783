import React from 'react';
import {
  Box, Typography, Select, MenuItem,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const customCheckbox = (status) => {
  if (status === 'done') {
    return (
      <TaskAltIcon sx={{ fontSize: 16, color: 'flashyGreen.main' }} />
    );
  }
  return (
    <RadioButtonUncheckedIcon sx={{ fontSize: 16 }} />
  );
};

// Custom status cell renderer
export function StatusCellRenderer({ params, t }) {
  const color = getStatusColor(params.value);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      {customCheckbox(params.value)}
      <Typography sx={{ color, fontSize: 14 }}>
        {t(`inventory_item.status.${params.value}`)}
      </Typography>
    </Box>
  );
}

// Custom status cell editor
export function StatusCellEditor({ params, t }) {
  const [value, setValue] = React.useState(params.value);

  const handleChange = (event) => {
    setValue(event.target.value);
    params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      fullWidth

    >
      <MenuItem value="toDo">{t('inventory_item.status.toDo')}</MenuItem>
      <MenuItem value="done">{t('inventory_item.status.done')}</MenuItem>
    </Select>
  );
}

// Helper function for determining status color
const getStatusColor = (status) => {
  switch (status) {
    case 'toDo':
      return '#437A89';
    case 'done':
      return '#1db954';
    default:
      return 'black';
  }
};
