import React, { useState } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, Paper, Box, Typography, Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function CreateInventoryButton({ organizationId, onAdded, fab = false }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleAddClick = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <CreateButton fab={fab} />
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>{t('create_inventory.start')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            size="large"
            variant="contained"
            component={Link}
            to="/admin/createInventory"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {t('create_inventory.create_barrel_inventory')}
            <b />
            {t('create_inventory.create_barrel_inventory_examples')}
          </Button>
          <Button
            disabled
            size="large"
            variant="contained"
            component={Link}
            to="createGCInventory"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {t('create_inventory.create_gc_inventory')}
            <b />
            {t('create_inventory.create_gc_inventory_examples')}
          </Button>
        </DialogContent>

      </Dialog>
    </>
  );
}

function CreateButton({ fab }) {
  const { t } = useTranslation();
  if (fab) {
    return (
      <Fab
        color="primary"
        aria-label="create"
        component={Link}
        to="/admin/createInventory"
        variant="extended"
        sx={{
          position: 'fixed', bottom: '5%', right: '5%',
        }}
      >
        <AddIcon />
        {t('create_inventory.start')}
      </Fab>
    );
  }
  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        cursor: 'pointer',
        minWidth: 320,
        textDecoration: 'none',
      }}
      component={Link}
      to="/admin/createInventory"
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: 1,
      }}
      >
        <AddIcon fontSize="large" />
        <Typography component="span">
          {t('create_inventory.start')}
        </Typography>
      </Box>
    </Paper>
  );
}

export default CreateInventoryButton;
