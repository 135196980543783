/* eslint-disable import/prefer-default-export */

export const validateFieldsAtSubmit = async (values, columnMappings, fillingStatus, globalActions, t) => {
  let measurementTypeIssue = false;
  const errors = [];

  if (fillingStatus === 'empty') {
    return true;
  }

  const volumeFieldsSubmit = !!values.liquidVolume.value
    || !!values.emptyHeight.value
    || !!values.liquidHeight.value
    || !!values.volumeTemp.value;

  const tavFieldsSubmit = !!values.tav.value
    || !!values.tavTemp.value;

  if (!volumeFieldsSubmit && !tavFieldsSubmit) {
    errors.push(t('operation.barrel.errors.noFieldsProvided'));
    globalActions.setSnackbarMessage({ message: t('volume_or_tav_fields'), severity: 'warning' });
    return false;
  }

  const liquidVolumeDirectlyProvided = !!values.liquidVolume.value;

  // Mapping of CSV names to form field paths
  const csvToFormField = {
    [columnMappings.usableCapacity.csvName]: 'usableCapacity',
    [columnMappings.totalCapacity.csvName]: 'totalCapacity',
    [columnMappings.heightUnderBung.csvName]: 'diameterBond',
    [columnMappings.emptyHeight.csvName]: 'emptyHeight',
    [columnMappings.liquidHeight.csvName]: 'liquidHeight',
    [columnMappings.liquidVolume.csvName]: 'liquidVolume',
    [columnMappings.volumeTemperature.csvName]: 'volumeTemp',
    [columnMappings.tav.csvName]: 'tav',
    [columnMappings.tavTemperature.csvName]: 'tavTemp',
  };

  const fieldToLabel = {
    usableCapacity: t('barrel_inventory.column_options.usableCapacity'),
    totalCapacity: t('barrel_inventory.column_options.totalCapacity'),
    diameterBond: t('ullaging.diameter_bond'),
    emptyHeight: t('ullaging.empty_height'),
    liquidHeight: t('ullaging.liquid_height'),
    liquidVolume: t('ullaging.liquid_volume'),
    volumeTemp: t('forms.liquid_temp'),
    tav: t('forms.measured_tav'),
    tavTemp: t('forms.measured_temp'),
  };

  const requiredFieldsBasedOnStatus = [];

  if (volumeFieldsSubmit) {
    if (!liquidVolumeDirectlyProvided && fillingStatus === 'full') {
      requiredFieldsBasedOnStatus.push(columnMappings.usableCapacity.csvName);
    }
    if (!liquidVolumeDirectlyProvided && fillingStatus === 'draining') {
      requiredFieldsBasedOnStatus.push(columnMappings.totalCapacity.csvName);
      requiredFieldsBasedOnStatus.push(columnMappings.heightUnderBung.csvName);

      const emptyHeight = !!values.emptyHeight.value;
      const liquidHeight = !!values.liquidHeight.value;

      if (!emptyHeight && !liquidHeight) {
        errors.push(t('needs_volume_calculation_field', {
          emptyHeight: columnMappings.emptyHeight.csvName,
          liquidHeight: columnMappings.liquidHeight.csvName,
          liquidVolume: columnMappings.liquidVolume.csvName,
        }));
      }
      if (!values.volumeTemp.value) {
        requiredFieldsBasedOnStatus.push(columnMappings.volumeTemperature.csvName);
      }
    }
  }

  if (tavFieldsSubmit) {
    if (!values.tavTemp.value) {
      requiredFieldsBasedOnStatus.push(columnMappings.tavTemperature.csvName);
    }
    if (!values.tav.value) {
      requiredFieldsBasedOnStatus.push(columnMappings.tav.csvName);
    }
  }

  requiredFieldsBasedOnStatus.forEach((csvName) => {
    const fieldName = csvToFormField[csvName];
    const labelname = fieldToLabel[fieldName];
    if (!values[fieldName].value) {
      errors.push(t('field_invalid_or_missing', { field: labelname }));
    }
  });

  switch (values.measurementType) {
    case 'liquidHeight':
      if (parseFloat(values.liquidHeight.value) > parseFloat(values.diameterBond.value)) {
        measurementTypeIssue = true;
      }
      break;
    case 'emptyHeight':
      if (parseFloat(values.emptyHeight.value) > parseFloat(values.diameterBond.value)) {
        measurementTypeIssue = true;
      }
      break;
    default:
      break;
  }

  if (measurementTypeIssue) {
    errors.push(t('error.ullaging_impossible'));
  }

  if (errors.length > 0) {
    const combinedErrorMessage = errors.join(', ');
    globalActions.setSnackbarMessage({ message: combinedErrorMessage, severity: 'warning' });

    return false;
  }

  return true;
};
