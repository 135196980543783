import React from 'react';
import { useFormik } from 'formik';
import {
  Dialog, Box, TextField, Button,
} from '@mui/material';
import { useFirestore } from 'reactfire';
import { addDoc, collection } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

export default function ReportIncidentDialog({
  open, setOpen, inventoryId, containerId, inventoryName,
}) {
  const firestore = useFirestore();
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const formik = useFormik({
    initialValues: {
      operatorName: localStorage.getItem('labox-inventory-operatorName') || '',
      message: '',
      timestamp: new Date(),
      inventoryId,
      containerId,
      inventoryName,
    },
    onSubmit: async (values) => {
      localStorage.setItem('labox-inventory-operatorName', values.operatorName);
      addDoc(collection(
        firestore,
        `/organizations/${globalState.activeOrganization}/apps/inventory/incidents`,
      ), values);
      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', duration: 2000 });
      setOpen(false);
    },
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box component="form" onSubmit={formik.handleSubmit} p={2}>
        <TextField
          name="operatorName"
          label={t('operation.operator_name')}
          value={formik.values.operatorName}
          onChange={formik.handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="message"
          label="Message"
          value={formik.values.message}
          onChange={formik.handleChange}
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />
        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
          <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
          <Button type="submit" variant="contained">{t('done')}</Button>
        </Box>
      </Box>
    </Dialog>
  );
}
