import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {
  where,
} from 'firebase/firestore';
import { useUser } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import OurPagination from 'components/OurPagination';
import moment from 'moment';
import Filters from './Filters';
import CreateInventoryButton from '../CreateInventoryButton';
import InventoryList from './InventoryList';
import ExportAll from './ExportAll';

export default function Inventories() {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const analytics = getAnalytics();
  const [globalState] = useGlobal();
  const [queryCount, setQueryCount] = useState(undefined);

  const computeListOfConditions = useCallback((formikValues) => {
    const conditions = [
      where('timestamp', '<=', formikValues.endDate.toDate()),
      where('timestamp', '>=', formikValues.startDate.toDate()),
    ];
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    return moment().subtract(6, 'months').startOf('day');
  }

  function initialEndDate() {
    return moment().endOf('day');
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_inventories', {
        user_uid: user?.uid,
        appName: 'Inventory',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: '100%',
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('inventories')}
      </Typography>
      {globalState.activeOrganization !== '' && (
      <OurPagination
        setQueryCount={setQueryCount}
        collectionPath={`organizations/${globalState.activeOrganization}/apps/inventory/inventories`}
        listOfConditions={listOfConditions}
        orderByAttribute="timestamp"
        filters={(
          <InventoryListFilters
            formikInitValues={formikInitValues}
            setListOfConditions={setListOfConditions}
            computeListOfConditions={computeListOfConditions}
            listOfConditions={listOfConditions}
            queryCount={queryCount}
          />
            )}
        list={(
          <InventoryList listOfConditions={listOfConditions} />
            )}
      />
      )}
      {globalState.activeOrganization === '' && <CircularProgress />}
      <CreateInventoryButton fab />
    </Box>
  );
}

function InventoryListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions,
  listOfConditions, queryCount,
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: formikInitValues,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <Filters formik={formik} datePickersLabel={t('admin.made')} />

      <ExportAll listOfConditions={listOfConditions} queryCount={queryCount} />

    </Box>
  );
}
