import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete, Box, Button, Tab, Tabs,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FutureInventories from './FutureInventories';

function CreateInventoryAction({
  inventoryName, setInventoryName, status, uploadInventory, containersArray, columnMappings, sortModel,
  filterModel,
  csvHeaders,
  fileName,
  checkInventoryExists,
  decimalSeparator,
}) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  return (
    <Box sx={{ pt: 6 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="cvreate_inventory_tabs"
          centered
        >
          <Tab label={t('create_single_inventory')} />
          <Tab label={t('create_multiple_inventory')} />
        </Tabs>
      </Box>

      {tabValue === 0 && (
      <Box p={3} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <TextField
          sx={{ alignSelf: 'center', width: '100%', maxWidth: 400 }}
          label={t('create_inventory.name')}
          value={inventoryName}
          onChange={(e) => setInventoryName(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <Typography variant="caption">
          {t('create_inventory.order_hint')}
        </Typography>

        <LoadingButton
          loading={status === 'loading'}
          size="large"
          sx={{ alignSelf: 'center' }}
          variant="contained"
          onClick={uploadInventory}
          disabled={status === 'loading'}
          endIcon={<CloudUploadIcon />}
        >
          {t('create_inventory.run_inventory')}
        </LoadingButton>
      </Box>
      )}
      {tabValue === 1
        && (
        <Box p={3} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <GroupBySelect
            containersArray={containersArray}
            columnMappings={columnMappings}
            sortModel={sortModel}
            filterModel={filterModel}
            csvHeaders={csvHeaders}
            fileName={fileName}
            decimalSeparator={decimalSeparator}
          />

        </Box>
        )}

    </Box>
  );
}

function GroupBySelect({
  columnMappings, containersArray, sortModel,
  filterModel,
  csvHeaders,
  fileName,
  checkInventoryExists,
  decimalSeparator,
}) {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const today = new Date().toLocaleDateString();

  const inventoriesArrayFromGroups = (groups) => Object.entries(groups).map(([group, ctrsArray]) => {
    const groupParts = group.split('::/::');
    return {
      group,
      inventoryName: `${groupParts.join(' - ')} - ${today}`,
      containersArray: ctrsArray,
    };
  });

  const [inventories, setInventories] = useState(inventoriesArrayFromGroups({ '': containersArray }));

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedColumns(newValue);
  };

  const { t } = useTranslation();

  const keysToFilter = [
    columnMappings.ivtContainerDisplayInfo.csvName,
    columnMappings.ivtContainerId.csvName,
    'id',
    'abvStatus',
    'volumeStatus',
  ];

  const options = Object.keys(containersArray[0])
    .filter((key) => !keysToFilter.includes(key));

  const groupBySelectedColumns = () => {
    const newGroupedContainers = containersArray.reduce((acc, container) => {
      const key = selectedColumns.map((column) => container[column]).join('::/::');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(container);
      return acc;
    }, {});
    setInventories(inventoriesArrayFromGroups(newGroupedContainers));
    setOpen(true);
  };

  return (
    <>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id="columns-group-select"
        options={options}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} label={t('group_by')} />
        )}
      />
      <Button
        size="large"
        sx={{ alignSelf: 'center' }}
        variant="contained"
        onClick={groupBySelectedColumns}
        endIcon={<ArrowForwardIosIcon />}
      >
        {t('see_inventories')}
      </Button>
      <FutureInventories
        inventories={inventories}
        setInventories={setInventories}
        columnMappings={columnMappings}
        sortModel={sortModel}
        filterModel={filterModel}
        csvHeaders={csvHeaders}
        fileName={fileName}
        open={open}
        setOpen={setOpen}
        decimalSeparator={decimalSeparator}
      />
    </>
  );
}

export default CreateInventoryAction;
