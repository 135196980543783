import React, { useEffect, useState } from 'react';
import {
  Box, Select, MenuItem, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AutocompleteAndUnitSelect from './AutocompleteAndUnitSelect';

function OptionalWithSelectAutocomplete({
  columnMappings,
  handleMappingChange,
  validCsvHeaders,
  isCsvHeaderValid,
  handleUnitChange,
  updateOptions,
  colOptions,
  defaultState,
  onSelectChange,
  selectOptions,
}) {
  const col = colOptions;

  const [showCompletion, setShowCompletion] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultState);
  const { t } = useTranslation();

  useEffect(() => {
    const defaultValue = columnMappings[col.name]?.csvName;
    if (!validCsvHeaders.includes(defaultValue)) {
      setShowCompletion(true);
      updateOptions(selectedValue, true);
    }
  }, [col.name, columnMappings, selectedValue, updateOptions, validCsvHeaders]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onSelectChange(newValue);
  };

  const handleMappingChangeWrap = (colName, colType, newInputValue) => {
    if (!validCsvHeaders.includes(newInputValue)) {
      setShowCompletion(true);
      updateOptions(selectedValue, true);
    } else {
      setShowCompletion(false);
      updateOptions(selectedValue, false);
    }
    handleMappingChange(col.name, col.type, newInputValue);
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <AutocompleteAndUnitSelect
          key={col.name}
          col={col}
          columnMappings={columnMappings}
          handleMappingChange={handleMappingChangeWrap}
          validCsvHeaders={validCsvHeaders}
          isCsvHeaderValid={isCsvHeaderValid}
          handleUnitChange={handleUnitChange}
        />
        {showCompletion && (
        <Select value={selectedValue} onChange={handleSelectChange}>
            {selectOptions.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
        </Select>
        )}
      </Box>
      {showCompletion && (
      <Typography variant="caption" sx={{ color: 'orange' }}>
        {t('filling_completion')}
      </Typography>
      )}
    </Box>
  );
}

export default OptionalWithSelectAutocomplete;
