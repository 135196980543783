import React from 'react';
import { navigatorNetworkState } from 'components/utils';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

function userUnitsFromLocalStorage() {
  const units = localStorage.getItem('saved_units');
  if (!units) {
    return {
      volumeUnit: 'L',
      temperatureUnit: 'celsius',
      temperatureValue: '20',
      lengthUnit: 'cm',
      massUnit: 'Kg',
      alcoholUnit: 'AbVol',
      concentrationUnit: 'g/L',
    };
  }
  return units;
}

const initialState = {
  cookiesAccepted: false,
  userUnits: userUnitsFromLocalStorage(),
  clientPath: '',
  networkState: navigatorNetworkState(),
  userStatus: 'initial',
  globalSnackbar: {
    display: false, mesage: '', severity: 'info', displayDuration: 15000,
  },
  roles: [],
  activeOrganization: '',
  csvMappings: {},
  accessGrants: [],
  accessGrantNames: [],
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
