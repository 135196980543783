/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import modelBarrelInventory from 'staticFiles/modele-inventiare-futs.csv';
import {
  FormControl, InputLabel, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import * as XLSX from 'xlsx';
import useGlobal from 'global-state/store';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { LoadingButton } from '@mui/lab';
import FileDropzone from '../FileDropzone';

export default function InventoryFileDropzone(props) {
  const {
    errorHandler, aborthandler, callback, currentCsvMapping, setCurrentCsvMapping,
  } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('...');
  const [, globalActions] = useGlobal();
  const [isCsvFile, setIsCsvFile] = useState(true);
  const [loading, setLoading] = useState(false);

  const { columnDelimiter, startFromLine, decimalSeparator } = currentCsvMapping.options;

  const updateCsvMapping = (newOptions) => {
    const newMapping = { ...currentCsvMapping, options: { ...currentCsvMapping.options, ...newOptions } };
    setCurrentCsvMapping(newMapping);
  };

  function getExample() {
    return modelBarrelInventory;
  }

  const uploadCallback = (acceptedFiles) => {
    const newFile = acceptedFiles[0];
    setIsCsvFile(newFile.type === 'text/csv');
    setFileName(newFile.path);
    setFile(newFile);
  };

  function removeEmptyColumns(data) {
    if (data.length === 0) return data;

    const headers = data[0];
    const validColumnIndexes = headers
      .map((header, index) => (header !== null && header !== undefined && header !== '' ? index : null))
      .filter((index) => index !== null);

    return data.map((row) => validColumnIndexes.map((index) => (row[index] === undefined ? '' : row[index])));
  }

  function removeEmptyRows(data) {
    return data.filter((row) => row.some((cell) => cell !== null && cell !== undefined && cell !== ''));
  }

  const parseFile = () => {
    setLoading(true);
    try {
      if (!file) {
        globalActions.setSnackbarMessage({ message: t('csv.missing_file'), severity: 'error' });
        setLoading(false);
        return;
      }

      const reader = new FileReader();
      reader.onerror = errorHandler;
      reader.onabort = aborthandler;

      reader.onload = (e) => {
        if (file.type === 'text/csv') {
          const allLines = e.target.result.split(/\r\n|\n/);

          // Check if file has fewer lines than startFromLine
          if (allLines.length < startFromLine) {
            globalActions.setSnackbarMessage({
              message: t('csv.not_enough_lines', { nbOfLines: startFromLine }),
              severity: 'error',
            });
            setLoading(false);
            return;
          }

          const linesToParse = allLines.slice(startFromLine - 1).join('\n');
          const parsedCsvData = Papa.parse(linesToParse, {
            header: false,
            dynamicTyping: true,
            delimiter: columnDelimiter,
            skipEmptyLines: true,
          }).data;

          let cleanedCsvData = removeEmptyRows(parsedCsvData);
          cleanedCsvData = removeEmptyColumns(cleanedCsvData);
          callback(cleanedCsvData, fileName);
        } else {
          // Handle Excel file
          const data = new Uint8Array(e.target.result);

          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          let parsedExcel = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Check if file has fewer lines than startFromLine
          if (parsedExcel.length < startFromLine) {
            globalActions.setSnackbarMessage({
              message: t('csv.not_enough_lines', { nbOfLines: startFromLine }),
              severity: 'error',
            });
            setLoading(false);
            return;
          }

          parsedExcel = parsedExcel.slice(startFromLine - 1);
          let cleanedExcelData = removeEmptyRows(parsedExcel);
          cleanedExcelData = removeEmptyColumns(cleanedExcelData);
          callback(cleanedExcelData, fileName);
        }
        setLoading(false);
      };

      file.type === 'text/csv' ? reader.readAsText(file) : reader.readAsArrayBuffer(file);
    } catch (err) {
      setLoading(false);
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        mt: 5,
      }}
    >
      <FileDropzone
        text={t('barrel_inventory.dropzone')}
        callback={uploadCallback}
        accept={{
          'application/octet-stream': ['.bar'],
          'text/csv': ['.csv'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-excel': ['.xls', '.xlsx'], // For older Excel formats
        }}
        fileName={fileName}
      />
      <a href={getExample()} style={{ textDecoration: 'none' }} download>
        <Button
          variant="outlined"
          component="span"
          startIcon={<DescriptionIcon />}
        >
          {t('menu_boxettes.download_model_CSV')}
        </Button>
      </a>
      <Typography>{`${t('csv.options')}:` }</Typography>
      <Box sx={{
        display: 'flex', flexDirection: 'row', gap: 1, flexWarp: 'wrap',
      }}
      >
        {isCsvFile && (
        <FormControl size="small" sx={{ width: 140 }}>
          <InputLabel id="columnDelimiterLabel">{t('csv.columns')}</InputLabel>
          <Select
            labelId="columnDelimiterLabel"
            id="columnDelimiter"
            value={columnDelimiter}
            onChange={(e) => updateCsvMapping({ columnDelimiter: e.target.value })}
            label={t('csv.columns')}
          >
            <MenuItem value=",">,</MenuItem>
            <MenuItem value=";">;</MenuItem>
          </Select>
        </FormControl>
        )}
        <FormControl size="small" sx={{ width: 90 }}>
          <InputLabel id="decimalSeparatorLabel">{t('csv.decimals')}</InputLabel>
          <Select
            labelId="decimalSeparatorLabel"
            id="columnDelimiter"
            value={decimalSeparator}
            onChange={(e) => updateCsvMapping({ decimalSeparator: e.target.value })}
            label={t('csv.decimals')}
          >
            <MenuItem value=",">,</MenuItem>
            <MenuItem value=".">.</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ width: 130 }}
          label={t('csv.startFromLine')}
          type="number"
          inputProps={{ min: 1 }}
          value={startFromLine !== undefined ? startFromLine : ''}
          onChange={(e) => {
            const newLineValue = e.target.value;
            updateCsvMapping({ startFromLine: newLineValue ? parseInt(newLineValue, 10) : 1 });
          }}
          size="small"
        />
      </Box>
      <LoadingButton loading={loading} variant="contained" onClick={parseFile} endIcon={<PlayCircleOutlineIcon />}>
        {t('csv.parse')}
      </LoadingButton>

    </Box>
  );
}
