import {
  Box, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { StatusCellRenderer } from './StatusCellRenderer';

export default function FutureInventory({
  inventory, columnMappings, changeInventoryName,
}) {
  const [inventoryName, setInventoryName] = useState(inventory.inventoryName);
  const { t } = useTranslation();
  const groupParts = inventory.group.split('::/::');
  const volumeStatusColumn = {
    field: 'volumeStatus',
    headerName: t('inventory_item.status_label.volumeStatus'),
    width: 120,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: (params) => <StatusCellRenderer params={params} t={t} />,
  };

  const abvStatusColumn = {
    field: 'abvStatus',
    headerName: t('inventory_item.status_label.abvStatus'),
    width: 120,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: (params) => <StatusCellRenderer params={params} t={t} />,
  };

  const handleChangeName = (e) => {
    setInventoryName(e.target.value);
  };

  const handleBlurName = (e) => {
    changeInventoryName(inventory.inventoryName, e.target.value);
  };

  const createColumnDefinition = (key) => {
    let columnWidth;
    let customColumn;

    // Handle custom columns like ivtContainerIdColumn or statusColumn
    if (key === 'abvStatus') {
      customColumn = abvStatusColumn;
    }
    if (key === 'volumeStatus') {
      customColumn = volumeStatusColumn;
    }

    // Set column width (customize as needed)
    switch (key) {
      case columnMappings.heightUnderBung.csvName:
      case columnMappings.totalCapacity.csvName:
      case columnMappings.liquidHeight?.csvName:
      case columnMappings.emptyHeight?.csvName:
      case columnMappings.tav?.csvName:
      case columnMappings.volumeTemperature?.csvName:
      case columnMappings.liquidVolume?.csvName:
      case columnMappings.ABVAt20cResult?.csvName:
      case columnMappings.pureAlcoholVolumeAt20cResult?.csvName:
      case columnMappings.liquidVolumeResult?.csvName:
      case columnMappings.volumeAt20cResult?.csvName:
      case columnMappings.volumeCorrectionFactorResult?.csvName:
      case t('create_inventory.default_mapping_fields.volumeUnit'):
      case columnMappings.evaporation?.csvName:
      case columnMappings.percentEvaporation?.csvName:
        columnWidth = 150;
        break;
      default:
        columnWidth = 110;
    }

    // Return the custom column if defined, otherwise return standard column definition
    return customColumn || {
      field: key,
      headerName: key,
      width: columnWidth,
      valueGetter: (value, row, column, apiRef) => row[key],
      editable: false,
      sortable: false, // disable sorting
      filterable: false, // d
    };
  };

  const columns = Object.keys(inventory.containersArray[0]).map(createColumnDefinition);

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
    }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        {groupParts.map((part, index) => (
          <Typography
            key={part}
            component="span"
            sx={{ color: index % 2 === 0 ? 'primary.main' : 'secondary.main', fontWeight: 'bold' }}
          >
            {part}
            {' '}
          </Typography>
        ))}
      </Box>
      <Box sx={{
        width: '90vw',
        maxWidth: 1500,
      }}
      >
        <DataGrid
          autoHeight
          rows={inventory.containersArray}
          columns={columns}
          rowHeight={25}
          pageSize={5}
        />
      </Box>
      <TextField
        sx={{ alignSelf: 'center', width: '100%', maxWidth: 400 }}
        label={t('create_inventory.name')}
        value={inventoryName}
        onChange={handleChangeName}
        onBlur={handleBlurName}
        variant="outlined"
        fullWidth
        margin="normal"
      />
    </Box>
  );
}
