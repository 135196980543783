import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirestore, useFirestoreDoc } from 'reactfire';
import {
  doc,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import {
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InventoryItems from './InventoryItems';

export default function Inventory() {
  const location = useLocation();

  const navigate = useNavigate();

  const inventoryId = location.state?.inventory;

  useEffect(() => {
    if (inventoryId === undefined) {
      navigate('/');
    }
  }, [inventoryId, navigate]);

  if (!inventoryId) {
    return <CircularProgress />;
  }

  return <InventoryWithLocation inventoryId={inventoryId} />;
}

function InventoryWithLocation({ inventoryId }) {
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [pageStatus, setPageStatus] = useState('loading');
  const navigate = useNavigate();

  const inventoryRef = doc(
    db,
    `organizations/${globalState.activeOrganization}/apps/inventory/inventories`,
    inventoryId,
  );

  const { status, data: inventorySnapshot } = useFirestoreDoc(inventoryRef);

  useEffect(() => {
    if (inventorySnapshot?.exists()) {
      setInventoryDetails({ id: inventorySnapshot.id, ...inventorySnapshot.data() });
      setPageStatus('success');
    } else {
      setInventoryDetails(null);
    }

    if (status === 'error') {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')}`, severity: 'warning' });
      navigate('/');
    }
  }, [inventorySnapshot, status, globalActions, navigate, t]);

  if (pageStatus !== 'success') {
    return <CircularProgress />;
  }
  return <InventoryItems inventoryDetails={inventoryDetails} setInventoryDetails={setInventoryDetails} />;
}
