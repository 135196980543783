import {
  collection,
  doc, writeBatch,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { createNewFilename } from './createNewFilename';

const applySorting = (data, sortModel) => {
  const dataCopy = [...data]; // Create a shallow copy of the array
  return dataCopy.sort((a, b) => {
    for (const sort of sortModel) {
      if (a[sort.field] < b[sort.field]) {
        return sort.sort === 'asc' ? -1 : 1;
      }
      if (a[sort.field] > b[sort.field]) {
        return sort.sort === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });
};

const applyFiltering = (data, filterModel) => data.filter((row) => filterModel.items.every((filter) => {
  if (filter.value === undefined) {
    return true;
  }
  const fieldValue = row[filter.field];
  switch (filter.operator) {
    case 'contains':
      return fieldValue.includes(filter.value);
    case 'equals':
      return fieldValue === filter.value;
    case 'startsWith':
      return fieldValue.startsWith(filter.value);
    case 'endsWith':
      return fieldValue.endsWith(filter.value);
    case 'isEmpty':
      return fieldValue === '';
    case 'isNotEmpty':
      return fieldValue !== '';
    case 'isAnyOf':
      return filter.value.includes(fieldValue);
    default:
      return true;
  }
}));

async function checkInventoryNames(inventories) {
  for (const inventory of inventories) {
    if (!inventory.inventoryName || inventory.inventoryName.trim() === '') {
      return false;
    }
  }
  return true;
}

async function checkInventories(inventories, checkInventoryExists) {
  const checkPromises = inventories.map((inventory) => checkInventoryExists(inventory.inventoryName));
  return Promise.all(checkPromises);
}

export default async function uploadInventories(
  inventories,
  setStatus,
  globalActions,
  t,
  navigate,
  sortModel,
  filterModel,
  columnMappings,
  csvHeaders,
  fileName,
  checkInventoryExists,
  firestore,
  globalState,
  decimalSeparator,
) {
  try {
    setStatus('loading');

    if (!checkInventoryNames(inventories)) {
      globalActions.setSnackbarMessage({
        message: `${t('create_inventory.already_exists')}, ${t('create_inventory.name_required')}`,
        severity: 'error',
      });
      return;
    }

    const checkResults = await checkInventories(inventories, checkInventoryExists);
    if (!checkResults) {
      globalActions.setSnackbarMessage({
        message: `${t('create_inventory.already_exists')}, ${t('create_inventory.name_required')}`,
        severity: 'error',
      });
      return;
    }

    const batch = writeBatch(firestore);

    for (const inventory of inventories) {
      const sortedData = applySorting(inventory.containersArray, sortModel);
      const filteredSortedData = applyFiltering(sortedData, filterModel);

      const newInventoryDoc = {
        timestamp: new Date(),
        containersArray: filteredSortedData,
        columnMappings,
        name: inventory.inventoryName,
        status: 'initial',
        originalDataColumnsOrder: csvHeaders,
        fileName: createNewFilename(fileName),
        decimalSeparator,
      };

      const newInventoryCollection = collection(
        firestore,
        `/organizations/${globalState.activeOrganization}/apps/inventory/inventories`,
      );
      const newDocRef = doc(newInventoryCollection, uuidv4());
      batch.set(newDocRef, newInventoryDoc);

      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    }

    await batch.commit();
    navigate('/admin');
  } catch (err) {
    globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
    throw err;
  } finally {
    setStatus('initial');
  }
}
