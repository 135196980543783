import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import OrganizationsSelect from 'components/OrganizationsSelect';
import { userInventoryAppAccess } from 'components/utils';
import useGlobal from 'global-state/store';
import CreateInventoryButton from './CreateInventoryButton';

export default function Admin() {
  const { t } = useTranslation();
  const [globalState] = useGlobal();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        px: { xs: 2, md: 10 },
        mt: { xs: 5, md: 10 },
        mb: 15,
      }}
    >
      <OrganizationsSelect />
      <CreateInventoryButton />
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3, alignSelf: 'center',
        }}
      >
        <MenuButton
          to="inventories"
          icon={<WarehouseIcon sx={{ fontSize: 150 }} />}
          text={t('inventories')}
        />
        <MenuButton
          component="a"
          target="_blank"
          href="https://orgs-ws.labox-apps.com"
          icon={<PeopleAlt sx={{ fontSize: 150 }} />}
          text={t('admin.users_management')}
        />
      </Box>
      {
        userInventoryAppAccess(globalState.accessGrantNames) === 'full' && (
        <Box
          sx={{
            display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3, alignSelf: 'center',
          }}
        >
          <MenuButton
            to="/admin/incidents"
            icon={<FormatListBulleted sx={{ fontSize: 150 }} />}
            text={t('incidents.title')}
          />
        </Box>
        )
      }

    </Box>
  );
}

function MenuButton(props) {
  const {
    disabled, to, icon, text, href, component, target,
  } = props;

  return (
    <Button
      disabled={disabled}
      color="info"
      sx={{
        p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: 300 },
      }}
      variant="contained"
      component={component || Link}
      href={href}
      target={target}
      to={to}
    >
      {icon}
      {text}
    </Button>
  );
}
