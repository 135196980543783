import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Tooltip } from '@mui/material';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import useGlobal from 'global-state/store';

export default function IncidentListItem(props) {
  const {
    index, incident, handleDeleteItem,
  } = props;
  const { t } = useTranslation();
  const firestore = getFirestore();
  const [globalState, globalActions] = useGlobal();

  const getDateString = (timestamp) => (timestamp ? new Date(timestamp.toMillis()).toLocaleString() : 'N/A');

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(
        firestore,
        `/organizations/${globalState.activeOrganization}/apps/inventory/incidents`,
        incident.id,
      ));
      handleDeleteItem();
    } catch (err) {
      globalActions
        .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
    }
  };

  return (
    <ListItem key={index} sx={{ py: 1 }} component="div">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <Typography sx={{ flexGrow: 3, flexBasis: 3 }}>
            {`${t('inventory')} : ${incident.inventoryName}`}
          </Typography>
          <Typography sx={{ flexGrow: 4, flexBasis: 4 }}>{`${t('tank')} : ${incident.containerId}`}</Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'end' }}>
            {getDateString(incident.timestamp)}
          </Typography>
          <Tooltip
            sx={{ flexGrow: 1, flexBasis: 1 }}
            title={t('incidents.close')}
          >
            <Button size="small" variant="outlined" onClick={handleDelete}>
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <Typography sx={{ flexGrow: 3, flexBasis: 3 }}>{`message : ${incident.message}`}</Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>
            {`${t('operation.operator_name')} : ${incident.operatorName}`}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
}
