import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import useGlobal from 'global-state/store';
import useMenu from 'menu-actions/useMenu';
import { useFirestore } from 'reactfire';
import {
  collection, doc, getDoc, getDocs, writeBatch,
} from 'firebase/firestore';
import { userInventoryAppAccess } from 'components/utils';
import InventoryListItem from './InventoryListItem';
import { downloadInventoriesAsCsv, downloadInventoriesAsXlsx } from './exports';

export default function InventoryList({
  docs, initSearch, listOfConditions,
}) {
  const navigate = useNavigate();
  const [globalState, globalActions] = useGlobal();
  const [selectedInventories, setSelectedInventories] = useState([]);
  const { dispatchMenuActions } = useMenu();
  const firestore = useFirestore();
  const { t } = useTranslation();

  const handleOpen = (inventory) => {
    navigate('inventory', {
      state: { inventoryId: inventory.id },
    });
  };

  const isSelected = (inventory) => selectedInventories.includes(inventory.id);

  const handleCheckInventory = (inventoryId, selected) => {
    const newSelectedInventories = selected
      ? [...selectedInventories, inventoryId]
      : selectedInventories.filter((invId) => invId !== inventoryId);
    setSelectedInventories(newSelectedInventories);
  };

  const handleDeleteSelected = useCallback(async () => {
    const batch = writeBatch(firestore);
    try {
      selectedInventories.forEach((inventoryId) => {
        const inventoryRef = doc(
          firestore,
          `/organizations/${globalState.activeOrganization}/apps/inventory/inventories/${inventoryId}`,
        );
        batch.delete(inventoryRef);
      });
      await batch.commit();
      globalActions
        .setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
      initSearch(listOfConditions);
    } catch (err) {
      globalActions
        .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  }, [firestore, selectedInventories, globalActions, t, initSearch, listOfConditions, globalState.activeOrganization]);

  const handleArchiveSelected = useCallback(async () => {
    const batch = writeBatch(firestore);

    try {
      const orgPath = `/organizations/${globalState.activeOrganization}/apps/inventory`;
      const promises = selectedInventories.map(async (inventoryId) => {
        const inventoryPath = `${orgPath}/inventories/${inventoryId}`;
        const archivePath = `${orgPath}/archivedInventories/${inventoryId}`;

        const originalDocRef = doc(firestore, inventoryPath);
        const archiveDocRef = doc(firestore, archivePath);

        const snapshot = await getDoc(originalDocRef);
        const inventory = snapshot.data();

        batch.set(archiveDocRef, { ...inventory });

        const subcollections = ['records'];
        const subcollectionPromises = subcollections.map(async (subcollection) => {
          const subcollectionRef = collection(firestore, `${inventoryPath}/${subcollection}`);
          const snapshotCol = await getDocs(subcollectionRef);
          snapshotCol.forEach((document) => {
            batch.set(doc(firestore, `${archivePath}/${subcollection}/${document.id}`), document.data());
          });
        });
        await Promise.all(subcollectionPromises);

        batch.delete(originalDocRef);
      });

      await Promise.all(promises);
      await batch.commit();

      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
      initSearch(listOfConditions);
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  }, [firestore, globalActions, globalState.activeOrganization, initSearch, listOfConditions, selectedInventories, t]);

  const deleteInventoryAction = useCallback(() => ({
    name: t('delete'),
    callback: () => handleDeleteSelected(),
  }), [handleDeleteSelected, t]);

  const archiveInventoryAction = useCallback(() => ({
    name: t('archive'),
    callback: () => handleArchiveSelected(),
  }), [handleArchiveSelected, t]);

  const fetchInventories = useCallback(async (inventoryIds) => {
    const orgPath = `/organizations/${globalState.activeOrganization}/apps/inventory`;

    const fetchPromises = inventoryIds.map((inventoryId) => {
      const inventoryPath = `${orgPath}/inventories/${inventoryId}`;
      const docRef = doc(firestore, inventoryPath);
      return getDoc(docRef);
    });

    const documents = await Promise.all(fetchPromises);
    return documents.map((docSnapshot) => {
      if (docSnapshot.exists()) {
        return { id: docSnapshot.id, ...docSnapshot.data() };
      }
      return null;
    }).filter((document) => document !== null);
  }, [firestore, globalState.activeOrganization]);

  const handleCSVExportSelected = useCallback(async () => {
    const inventories = await fetchInventories(selectedInventories, firestore);

    downloadInventoriesAsCsv(
      inventories,
      t('exported_inventory'),
      () => globalActions.setSnackbarMessage({
        message: t('success'),
        severity: 'success',
        duration: 2000,
      }),
      t,
    );
  }, [fetchInventories, firestore, globalActions, selectedInventories, t]);

  const handleXslxExportSelected = useCallback(async () => {
    const inventories = await fetchInventories(selectedInventories, firestore);

    downloadInventoriesAsXlsx(
      inventories,
      t('exported_inventory'),
      () => globalActions.setSnackbarMessage({
        message: t('success'),
        severity: 'success',
        duration: 2000,
      }),
      t,
    );
  }, [fetchInventories, firestore, globalActions, selectedInventories, t]);

  const exportCSVAction = useCallback(() => ({
    name: `${t('Exporter')} CSV`,
    callback: () => handleCSVExportSelected(),
  }), [handleCSVExportSelected, t]);

  const exportXslxAction = useCallback(() => ({
    name: `${t('Exporter')} XLSX`,
    callback: () => handleXslxExportSelected(),
  }), [handleXslxExportSelected, t]);

  useEffect(() => {
    dispatchMenuActions({
      type: 'set',
      actions: [deleteInventoryAction(),
        archiveInventoryAction(), exportCSVAction(), exportXslxAction()],
    });
  }, [deleteInventoryAction, archiveInventoryAction, dispatchMenuActions, exportXslxAction, exportCSVAction]);

  const displayStatuses = userInventoryAppAccess(globalState.accessGrantNames) === 'full';

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <ListHeader
        selectedInventories={selectedInventories}
        docs={docs}
        setSelectedInventories={setSelectedInventories}
      />
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {docs.map((op, i) => (
            <div key={op.id}>
              <InventoryListItem
                isSelected={isSelected(op)}
                inventory={{ id: op.id, ...op.data() }}
                index={i}
                handleOpen={handleOpen}
                handleCheckInventory={handleCheckInventory}
                displayStatuses={displayStatuses}
              />
              <Divider />
            </div>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

function ListHeader({ selectedInventories, docs, setSelectedInventories }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography>
        <Checkbox
          sx={{ alignSelf: 'flex-start' }}
          size="small"
          checked={selectedInventories.length === docs.length}
          indeterminate={selectedInventories.length > 0 && selectedInventories.length < docs.length}
          onChange={(event) => {
            if (event.target.checked) {
              const newSelectedInventories = docs.map((op) => op.id);
              setSelectedInventories(newSelectedInventories);
            } else {
              setSelectedInventories([]);
            }
          }}
        />
        {t('create_inventory.name')}
      </Typography>

      <Typography sx={{ width: 155 }}>{t('date')}</Typography>
    </Box>
  );
}
