/* eslint-disable import/prefer-default-export */

import {
  getAllMappedColumnNames,
  getUnmappedRequiredColumns,
  isAnyMandatoryEvaporationColumnFilled,
  variantConversionResultColumnOptions, variantEvaporationColumnOptions,
  variantEvaporationResultColumnOptions, variantFromInputColumnOptions,
  variantFromInputOptionalColumnOptions, variantOperatorRecordColumnOptions,
} from '../columnsUtils';

const findDuplicates = (array) => {
  const seen = new Set();
  const duplicates = new Set();
  array.forEach((item) => {
    if (seen.has(item)) {
      duplicates.add(item);
    }
    seen.add(item);
  });
  return [...duplicates];
};

export const handleSubmit = (
  columnOptions,
  columnMappings,
  validCsvHeaders,
  globalActions,
  t,
  handleMappingComplete,
  newCsvMappingName,
  setCsvMappingName,
) => {
  const unmappedRequiredColumnsVariant1 = getUnmappedRequiredColumns(
    variantFromInputColumnOptions(columnOptions),
    columnMappings,
    validCsvHeaders,
    true,
  );

  const otherVariantOptions = [
    ...variantConversionResultColumnOptions(columnOptions),
    ...variantOperatorRecordColumnOptions(columnOptions),
    ...variantFromInputOptionalColumnOptions(columnOptions),
  ];

  const unmappedRequiredColumnsVariant2and3 = getUnmappedRequiredColumns(
    otherVariantOptions,
    columnMappings,
    validCsvHeaders,
  );

  const allUnmappedRequiredColumns = [...unmappedRequiredColumnsVariant1, ...unmappedRequiredColumnsVariant2and3];

  const allColumnOptions = [
    ...variantFromInputColumnOptions(columnOptions),
    ...variantFromInputOptionalColumnOptions(columnOptions),
    ...variantConversionResultColumnOptions(columnOptions),
    ...variantOperatorRecordColumnOptions(columnOptions),
  ];
  const allMappedColumnNames = getAllMappedColumnNames(allColumnOptions, columnMappings);
  const duplicateColumnNames = findDuplicates(allMappedColumnNames);

  if (allUnmappedRequiredColumns.length > 0) {
    const missingColumnNames = allUnmappedRequiredColumns.map(
      (name) => t(`barrel_inventory.column_options.${name}`),
    );

    globalActions.setSnackbarMessage({
      message: `${t('barrel_inventory.missing_columns')} ${missingColumnNames.join(', ')}`,
      severity: 'warning',
    });
    return;
  }

  if (duplicateColumnNames.length > 0) {
    globalActions.setSnackbarMessage({
      message: `${t('barrel_inventory.duplicate_columns')} ${duplicateColumnNames.join(', ')}`,
      severity: 'warning',
    });
    return;
  }

  const anyMandatoryEvaporationFilled = isAnyMandatoryEvaporationColumnFilled(columnOptions, columnMappings);

  const requiredEvaporationColumns = [...variantEvaporationResultColumnOptions(columnOptions),
    ...variantEvaporationColumnOptions(columnOptions)]
    .filter((col) => col.required)
    .map((col) => col.name);

  let unmappedOrUnfilledMandatoryEvaporationColumns = [];
  if (anyMandatoryEvaporationFilled) {
    unmappedOrUnfilledMandatoryEvaporationColumns = requiredEvaporationColumns
      .filter((name) => !columnMappings[name]?.csvName); // Check if the column is not mapped or filled
  }

  // If there are unmapped required columns and any required column is filled, it's a validation error
  if (anyMandatoryEvaporationFilled && unmappedOrUnfilledMandatoryEvaporationColumns.length > 0) {
    const missingColumnNames = unmappedOrUnfilledMandatoryEvaporationColumns.map(
      (name) => t(`barrel_inventory.column_options.${name}`),
    );

    globalActions.setSnackbarMessage({
      message: `${t('barrel_inventory.missing_columns')} ${missingColumnNames.join(', ')}`,
      severity: 'warning',
    });
    return;
  }
  handleMappingComplete();
};
