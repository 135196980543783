import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse, Divider, IconButton, List, Paper, Typography,
} from '@mui/material';
import {
  collection, getDocs, orderBy, query, where,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useGlobal from 'global-state/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import IncidentListItem from '../incidents/IncidentListItem';

export default function InventoryIncidentsWarning({ inventory }) {
  const [incidents, setIncidents] = useState({ state: 'initial', data: [] });
  const firestore = useFirestore();
  const [globalState] = useGlobal();
  const [displayAll, setDisplayAll] = useState(false);
  const { t } = useTranslation();

  const incidentsRef = collection(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/inventory/incidents`,
  );

  useEffect(() => {
    const loadIncidents = async () => {
      setIncidents({ state: 'loading', data: [] });
      const incidentsQuery = query(
        incidentsRef,
        where('inventoryId', '==', inventory.NO_ID_FIELD),
        orderBy('timestamp', 'desc'),
      );

      const querySnapshot = await getDocs(incidentsQuery);
      const incidentsData = querySnapshot.docs.map((currentDoc) => ({ id: currentDoc.id, ...currentDoc.data() }));
      setIncidents({ state: 'loaded', data: incidentsData });
    };

    if (incidents.state === 'initial') {
      loadIncidents();
    }
  }, [firestore, incidents.state, incidentsRef, inventory.NO_ID_FIELD]);

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  if (incidents.data.length > 0) {
    return (
      <Paper
        elevation={0}
        sx={{
          p: 1,
          border: 1,
          borderColor: 'ourOrange.main',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
            pointer: 'cursor',
          }}
          onClick={() => setDisplayAll(!displayAll)}
        >
          <WarningIcon sx={{ color: 'ourOrange.main' }} size="small" />
          <Typography>
            {t('incidents.inventory_has_incidents')}
          </Typography>
          <IconButton>
            {expandIcon()}
          </IconButton>
        </Box>
        <Collapse
          in={displayAll}
          sx={{
            width: '100%',
          }}
        >
          <List dense sx={{ width: '100%' }}>
            {incidents.data.map((inv, i) => (
              <div key={inv.id}>
                <IncidentListItem
                  incident={inv}
                  index={i}
                  handleDeleteItem={() => setIncidents({ state: 'initial', data: [] })}
                />
                <Divider />
              </div>
            ))}
          </List>
        </Collapse>
      </Paper>
    );
  }
  return null;
}
