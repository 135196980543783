const variants1 = {
  empty: 'empty',
  full: 'full',
  draining: 'draining',
};

const variants2 = {
  empty: 'vide',
  full: 'plein',
  draining: 'vidange',
};

const variants3 = {
  empty: '0%',
  full: '100%',
  draining: '50%',
};

const variants4 = {
  empty: 'v',
  full: 'p',
  draining: 'e',
};

const statusMappings = {
  full: {
    currentStatus: 'full',
    variants: variants1,
  },
  plein: {
    currentStatus: 'full',
    variants: variants2,
  },
  '100%': {
    currentStatus: 'full',
    variants: variants3,
  },
  p: {
    currentStatus: 'full',
    variants: variants4,
  },
  empty: {
    currentStatus: 'empty',
    variants: variants1,
  },
  vide: {
    currentStatus: 'empty',
    variants: variants2,
  },
  '0%': {
    currentStatus: 'empty',
    variants: variants3,
  },
  v: {
    currentStatus: 'empty',
    variants: variants4,
  },
  draining: {
    currentStatus: 'draining',
    variants: variants1,
  },
  vidange: {
    currentStatus: 'draining',
    variants: variants2,
  },
  e: {
    currentStatus: 'draining',
    variants: variants4,
  },
};

export const fillingStatusFromValue = (input) => {
  // Convert the input to string and process it
  let processedInput = input.toString().toLowerCase();

  // Check for direct mappings first
  if (statusMappings[processedInput]) {
    // Return the currentStatus from the mapping
    return statusMappings[processedInput].currentStatus;
  }

  // Remove non-numeric characters except for dot
  processedInput = processedInput.replace(/[^0-9.]/g, '');

  // Parse the cleaned input as a float
  const numericValue = parseFloat(processedInput);

  if (!isNaN(numericValue)) {
    if (numericValue === 0 || numericValue === 0.0) {
      return 'empty';
    }
    if (numericValue === 1 || numericValue === 1.0 || numericValue === 100) {
      return 'full';
    }
    return 'draining';
  }

  return null;
};

// Answer the appropriate status based on the previousStatusRead in any format
// and the statusWanted being empty, full or draining
export const formattedFillingStatusBasedOnRead = (previousStatusRead, statusWanted) => {
  const hasUpperCase = (str) => /[A-Z]/.test(str);
  const processedRead = previousStatusRead.toString().toLowerCase();
  const processedWanted = statusWanted;
  let variants;

  if (statusMappings[processedRead]) {
    variants = statusMappings[processedRead].variants;
  } else {
    variants = variants3;
  }

  let wantedMapping = variants[processedWanted];
  if (hasUpperCase) {
    // Convert first letter to uppercase if the original had uppercase characters
    wantedMapping = wantedMapping.charAt(0).toUpperCase() + wantedMapping.slice(1);
  }

  return wantedMapping;
};
