import DownloadIcon from '@mui/icons-material/Download';
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadAsCsv, downloadAsXlsx, formatNumber } from './exports';

export default function ExportButton({ inventory, gridRef }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const excludeColumns = ['id', 'abvStatus', 'volumeStatus'];

  const getOrderdFilteredData = (asText = false) => {
    const allColumns = Object.keys(inventory.containersArray[0] || {});
    const columnOrder = allColumns
      .filter((col) => !excludeColumns.includes(col))
      .sort((a, b) => gridRef.current.getColumnIndex(a) - gridRef.current.getColumnIndex(b))
      .filter((col) => gridRef.current.getColumnIndex(col) !== -1);

    return inventory.containersArray.map((row) => {
      const newRow = {};
      columnOrder.forEach((column) => {
        newRow[column] = formatNumber(
          row[column],
          column,
          inventory,
          asText,
        );
      });
      return newRow;
    });
  };

  const handleDownloadAsXlsx = () => {
    downloadAsXlsx(
      inventory,
      inventory.fileName,
      handleClose,
      t,
      getOrderdFilteredData(),
    );
  };

  const handleDownloadAsCsv = () => {
    downloadAsCsv(
      inventory,
      inventory.fileName,
      handleClose,
      t,
      getOrderdFilteredData(true),
    );
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClick}
        endIcon={<DownloadIcon />}
      >
        {t('export')}
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleDownloadAsXlsx}
        >
          XLSX
        </MenuItem>
        <MenuItem onClick={handleDownloadAsCsv}>CSV</MenuItem>
      </Menu>
    </>
  );
}
