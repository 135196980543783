import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Container } from '@mui/material';
import {
  useInitFirestore,
  useFirebaseApp,
  AuthProvider,
  AnalyticsProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  RemoteConfigProvider,
  useInitRemoteConfig,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import {
  initializeFirestore, persistentLocalCache, persistentSingleTabManager,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import CssBaseline from '@mui/material/CssBaseline';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import LoadingPage from 'components/LoadingPage';
import Home from 'components/Home';
import { initI18next } from 'i18n/i18next';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'inventory.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:cd60feb77e4ad128297452',
  measurementId: 'G-FM7D8XN6KB',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    info: {
      main: '#437A89',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    flashyGreen: {
      main: '#1db954',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
    lightGray: {
      main: '#f0f0f0',
      contrastText: 'black',
    },
  },
});

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <MyFirestoreProvider />
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function MyFirestoreProvider() {
  const app = useFirebaseApp();
  const functionsInstance = getFunctions(app);
  const storageInstance = getStorage(app);

  const { status: firestoreStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const baseSettings = {
      localCache: persistentLocalCache({
        tabManager: persistentSingleTabManager({
          // forceOwnership for web worker
          // eslint-disable-next-line no-undef
          forceOwnership: !globalThis.localStorage,
        }),
      }),
    };
    const savedSetting = localStorage.getItem('forceLongPolling');
    const forceLongPolling = savedSetting === 'true';
    if (forceLongPolling) {
      baseSettings.experimentalForceLongPolling = true;
    } else {
      baseSettings.experimentalAutoDetectLongPolling = true;
    }
    const fsSettings = (process.env.NODE_ENV === 'development')
      ? {
        ...baseSettings,
        host: 'localhost:4401',
        ssl: false,
      } : baseSettings;
    const db = initializeFirestore(firebaseApp, fsSettings);
    return db;
  });

  const { status: remoteConfigStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 600000,
      fetchTimeoutMillis: 600000,
    };

    await fetchAndActivate(remoteConfig);
    return remoteConfig;
  });

  if (firestoreStatus === 'loading' || remoteConfigStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <DigiFunctionsProvider
        functionsInstance={functionsInstance}
        storageInstance={storageInstance}
        remoteConfigInstance={remoteConfigInstance}
      />
    </FirestoreProvider>
  );
}

function DigiFunctionsProvider(props) {
  const { functionsInstance, storageInstance, remoteConfigInstance } = props;

  // We are initializing emulator earlier due to bug mentionned in:
  // https://github.com/FirebaseExtended/reactfire/issues/443
  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
    connectStorageEmulator(storageInstance, 'localhost', 9199);
    // connectFirestoreEmulator(firestoreInstance, 'localhost', 5080);
  }

  return (
    <FunctionsProvider sdk={functionsInstance}>
      <StorageProvider sdk={storageInstance}>
        <RemoteConfigProvider sdk={remoteConfigInstance}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Container>
                <CssBaseline />
                <Home />
              </Container>
            </ThemeProvider>
          </StyledEngineProvider>
        </RemoteConfigProvider>
      </StorageProvider>
    </FunctionsProvider>
  );
}

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>
    </FirebaseAppProvider>
  );
}

export default App;
