import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { typeColumnOptions } from '../columnsUtils';
import OptionalWithSelectAutocomplete from './OptionalWithSelectAutocomplete';

function TypeAutocomplete({
  columnMappings,
  handleMappingChange,
  validCsvHeaders,
  isCsvHeaderValid,
  handleUnitChange,
  columnOptions,
  updateColumnOptions,
}) {
  const col = useMemo(() => typeColumnOptions(columnOptions), [columnOptions]);
  const { t } = useTranslation();

  const updateOptions = (val, boolean) => {
    updateColumnOptions(col.name, {
      ...col,
      toFill: boolean,
      typeValue: val,
    });
  };

  const onSelectChange = (newValue) => {
    updateColumnOptions(col.name, {
      ...col,
      typeValue: newValue,
    });
  };

  const selectOptions = [t('inventory_item.type.laying'), t('inventory_item.type.standing')];

  return (
    <OptionalWithSelectAutocomplete
      columnMappings={columnMappings}
      handleMappingChange={handleMappingChange}
      validCsvHeaders={validCsvHeaders}
      isCsvHeaderValid={isCsvHeaderValid}
      handleUnitChange={handleUnitChange}
      updateOptions={updateOptions}
      colOptions={col}
      defaultState={t('inventory_item.type.laying')}
      onSelectChange={onSelectChange}
      selectOptions={selectOptions}
    />
  );
}

export default TypeAutocomplete;
