/* eslint-disable react/jsx-props-no-spreading */
// TODO unspread props
import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { useDropzone } from 'react-dropzone';

export default function FileDropzone(props) {
  const { t } = useTranslation();
  const {
    callback, text, accept, disabled, fileName, maxFiles = 1, maxFileSize = 2,
  } = props;
  const MAX_FILE_SIZE_IN_MO = maxFileSize;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MO * 1024 * 1024;
  const [, globalActions] = useGlobal();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-invalid-type') {
          globalActions.setSnackbarMessage({ message: t('certificate_creation.file_invalid_type'), severity: 'error' });
        }
        if (err.code === 'file-too-large') {
          globalActions.setSnackbarMessage({
            message: t(
              'certificate_creation.file_too_big',
              { sizeInMo: MAX_FILE_SIZE_IN_MO },
            ),
            severity: 'error',
          });
        }
      });
    });
    if (acceptedFiles.length > 0) {
      callback(acceptedFiles);
    }
  }, [MAX_FILE_SIZE_IN_MO, callback, globalActions, t]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    MAX_FILE_SIZE,
    maxFiles,
    disabled,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Box
        {...getRootProps()}
        sx={{
          color: disabled ? '#808080' : '#437A89',
          border: 1,
          p: 5,
          borderRadius: 5,
          borderStyle: 'dashed',
        }}
      >
        <Typography
          sx={{ color: disabled ? '#808080' : '#437A89' }}
          variant="h6"
          gutterBottom
          component="div"
        >
          {text}
        </Typography>
        <div style={{ height: 10 }} />

        <Button variant="contained" color="info" disabled={disabled}>
          <InsertDriveFile />
          {fileName}
        </Button>
      </Box>
    </div>
  );
}
