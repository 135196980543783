const getDefaultColumnValues = (t) => ({
  ABVAt20cResult: t('create_inventory.default_mapping_fields.ABVAt20cResult'),
  liquidVolumeResult: t('create_inventory.default_mapping_fields.liquidVolumeResult'),
  volumeAt20cResult: t('create_inventory.default_mapping_fields.volumeAt20cResult'),
  pureAlcoholVolumeAt20cResult: t('create_inventory.default_mapping_fields.pureAlcoholVolumeAt20cResult'),
  volumeCorrectionFactorResult: t('create_inventory.default_mapping_fields.volumeCorrectionFactorResult'),
  liquidHeight: t('create_inventory.default_mapping_fields.liquidHeight'),
  emptyHeight: t('create_inventory.default_mapping_fields.emptyHeight'),
  liquidVolume: t('create_inventory.default_mapping_fields.liquidVolume'),
  tav: t('create_inventory.default_mapping_fields.tav'),
  tavTemperature: t('create_inventory.default_mapping_fields.tavTemperature'),
  volumeTemperature: t('create_inventory.default_mapping_fields.volumeTemperature'),
  evaporation: t('create_inventory.default_mapping_fields.evaporation'),
  percentEvaporation: t('create_inventory.default_mapping_fields.percentEvaporation'),
  filling: t('create_inventory.default_mapping_fields.filling'),
  type: t('create_inventory.default_mapping_fields.type'),
  usableCapacity: t('create_inventory.default_mapping_fields.usableCapacity'),
});

// eslint-disable-next-line import/prefer-default-export
export const getColumnOptions = (t) => {
  const defaultColumnValues = getDefaultColumnValues(t);
  return [
    {
      name: 'ivtContainerId',
      type: 'multi-string',
      variant: 'fromInput',
      required: true,
      defaultValue: [],
      requiresExistingColumn: true,
      cellType: 'string',
    },
    {
      name: 'ivtContainerDisplayInfo',
      type: 'multi-string',
      variant: 'fromInput',
      required: false,
      defaultValue: [],
      requiresExistingColumn: true,
      cellType: 'string',
    },
    {
      name: 'totalCapacity',
      type: 'volume',
      variant: 'fromInput',
      required: true,
      defaultValue: null,
      requiresExistingColumn: true,
      cellType: 'number',
    },
    {
      name: 'heightUnderBung',
      type: 'length',
      variant: 'fromInput',
      required: true,
      defaultValue: null,
      requiresExistingColumn: true,
      cellType: 'number',
    },
    {
      name: 'usableCapacity',
      type: 'volume',
      variant: 'fromInputOptional',
      required: true,
      defaultValue: null,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.usableCapacity,
      cellType: 'number',
    },
    {
      name: 'filling',
      type: 'string',
      variant: 'fromInputOptional',
      required: true,
      defaultValue: null,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.filling,
      cellType: 'string',
    },
    {
      name: 'type',
      type: 'string',
      variant: 'fromInputOptional',
      required: true,
      defaultValue: null,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.type,
      cellType: 'string',
    },
    {
      name: 'ABVAt20cResult',
      type: 'abv',
      variant: 'conversionResult',
      required: true,
      defaultValue: defaultColumnValues.ABVAt20cResult,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.ABVAt20cResult,
      cellType: 'number',
    },
    {
      name: 'liquidVolumeResult',
      type: 'volume',
      variant: 'conversionResult',
      required: true,
      defaultValue: defaultColumnValues.liquidVolumeResult,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.liquidVolumeResult,
      cellType: 'number',
    },
    {
      name: 'volumeAt20cResult',
      type: 'volume',
      variant: 'conversionResult',
      required: true,
      defaultValue: defaultColumnValues.volumeAt20cResult,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.volumeAt20cResult,
      cellType: 'number',
    },
    {
      name: 'pureAlcoholVolumeAt20cResult',
      type: 'volume',
      variant: 'conversionResult',
      required: true,
      defaultValue: defaultColumnValues.pureAlcoholVolumeAt20cResult,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.pureAlcoholVolumeAt20cResult,
      cellType: 'number',
    },
    {
      name: 'volumeCorrectionFactorResult',
      type: 'string',
      variant: 'conversionResult',
      required: true,
      defaultValue: defaultColumnValues.volumeCorrectionFactorResult,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.volumeCorrectionFactorResult,
      cellType: 'number',
    },
    {
      name: 'liquidHeight',
      type: 'string',
      variant: 'operatorRecord',
      required: true,
      defaultValue: defaultColumnValues.liquidHeight,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.liquidHeight,
      cellType: 'number',
    },
    {
      name: 'emptyHeight',
      type: 'string',
      variant: 'operatorRecord',
      required: true,
      defaultValue: defaultColumnValues.emptyHeight,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.emptyHeight,
      cellType: 'number',
    },
    {
      name: 'liquidVolume',
      type: 'volume',
      variant: 'operatorRecord',
      required: true,
      defaultValue: defaultColumnValues.liquidVolume,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.liquidVolume,
      cellType: 'number',
    },
    {
      name: 'tav',
      type: 'string',
      variant: 'operatorRecord',
      required: true,
      defaultValue: defaultColumnValues.tav,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.tav,
      cellType: 'number',
    },
    {
      name: 'tavTemperature',
      type: 'string',
      variant: 'operatorRecord',
      required: true,
      defaultValue: defaultColumnValues.tavTemperature,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.tavTemperature,
      cellType: 'number',
    },
    {
      name: 'volumeTemperature',
      type: 'string',
      variant: 'operatorRecord',
      required: true,
      defaultValue: defaultColumnValues.volumeTemperature,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.volumeTemperature,
      cellType: 'number',
    },
    {
      name: 'pureAlcoholTheoretical',
      type: 'string',
      variant: 'evaporation',
      required: true,
      defaultValue: null,
      requiresExistingColumn: false,
      cellType: 'number',
    },
    {
      name: 'evaporation',
      type: 'string',
      variant: 'evaporationResult',
      required: true,
      defaultValue: defaultColumnValues.evaporation,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.evaporation,
      cellType: 'number',
    },
    {
      name: 'percentEvaporation',
      type: 'string',
      variant: 'evaporationResult',
      required: true,
      defaultValue: defaultColumnValues.percentEvaporation,
      requiresExistingColumn: false,
      suggestion: defaultColumnValues.percentEvaporation,
      cellType: 'number',
    },
  ];
};
