/* eslint-disable import/prefer-default-export */
const moment = require('moment');

export function createNewFilename(originalFilename) {
  // Extract the base name and extension
  const baseName = originalFilename.substring(0, originalFilename.lastIndexOf('.'));
  const extension = originalFilename.substring(originalFilename.lastIndexOf('.'));

  // Use moment.js to format the current date and time
  const dateTimeFormat = moment().format('DDMMYYYY-HHmm');

  // Assemble the new filename
  const newFilename = `${baseName}-labox-${dateTimeFormat}${extension}`;

  return newFilename;
}
