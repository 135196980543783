import React from 'react';
import {
  Select, MenuItem, FormControl,
  InputLabel,
} from '@mui/material';
import useGlobal from 'global-state/store';

function SelectMapping({
  currentCsvMapping, callback,
}) {
  const [globalState] = useGlobal();
  const handleSelectionChange = (event) => {
    callback(event.target.value);
  };

  const isValueValid = Object.keys(globalState.csvMappings).includes(currentCsvMapping.name);

  return (
    <FormControl fullWidth sx={{ maxWidth: 400 }} size="small">
      <InputLabel id="csv-mapping-select-label">Mapping</InputLabel>
      <Select
        labelId="csv-mapping-select-label"
        id="csv-mapping-select"
        label="Mapping"
        onChange={handleSelectionChange}
        value={isValueValid ? currentCsvMapping.name : ''}
      >
        {Object.keys(globalState.csvMappings).map((key) => (
          <MenuItem key={key} value={key}>{key}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectMapping;
