import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import {
  useRemoteConfigString,
} from 'reactfire';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import version from 'staticFiles/version.txt';
import { remoteConfigStatus } from 'labox-ws-commons';
import Refresh from '@mui/icons-material/Refresh';
import Link from '@mui/material/Link';
import { clearApplicationCache, refreshSWAndReload } from './utils';

export default function RemoteConfigStatus(props) {
  const { defaultComponent } = props;
  const [myRemoteConfigStatus, setmyRemoteConfigStatus] = useState('none');
  const [myRemoteConfigInfo, setmyRemoteConfigInfo] = useState('');
  const { data: maintenanceData, status: maintenanceStatus } = useRemoteConfigString('maintenance');
  const { data: refreshAppData, status: refreshAppStatus } = useRemoteConfigString('refresh_app');
  const {
    data: refreshAppSpecificData,
    status: refreshAppSpecificStatus,
  } = useRemoteConfigString('refresh_app_specific');
  const { t } = useTranslation();
  const [appVersion, setappVersion] = useState('');

  useEffect(() => {
    async function fetchVersion() {
      const raw = await fetch(version);
      const textVersion = await raw.text();
      return textVersion;
    }

    async function setupRemoteConfigStatus() {
      const versionOfApp = await fetchVersion();
      setappVersion(versionOfApp);
      const status = remoteConfigStatus(
        {
          appName: 'inventory',
          appVersion: versionOfApp,
          maintenanceData,
          refreshAppData,
          refreshAppSpecificData,
        },
      );
      setmyRemoteConfigStatus(status.status);
      setmyRemoteConfigInfo(status.info);
    }

    if ([maintenanceStatus, refreshAppStatus, refreshAppSpecificStatus].every((status) => status === 'success')) {
      setupRemoteConfigStatus();
    }
  }, [maintenanceData, maintenanceStatus, refreshAppData,
    refreshAppSpecificData, refreshAppSpecificStatus, refreshAppStatus]);

  if (myRemoteConfigStatus === 'maintenance') {
    return (
      <MaintenanceOrRefresh
        appVersion={appVersion}
        text={t('remote_config.maintenance_on')}
        info={myRemoteConfigInfo}
      />
    );
  } if (myRemoteConfigStatus === 'refresh') {
    return (
      <MaintenanceOrRefresh
        displayCacheRefresh
        appVersion={appVersion}
        text={t('remote_config.should_refresh')}
        info={myRemoteConfigInfo}
      />
    );
  }
  return defaultComponent;
}

function MaintenanceOrRefresh(props) {
  const {
    text, info, appVersion, displayCacheRefresh = false,
  } = props;
  const { t } = useTranslation();

  return (
    <Paper sx={{
      mt: 2,
      p: 5,
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
    }}
    >
      <Typography component="span" variant="h6">
        {text}
      </Typography>
      <Typography component="span">
        {info}
      </Typography>
      <Button
        variant="contained"
        size="small"
        onClick={refreshSWAndReload}
        endIcon={<Refresh />}
      >
        {t('reload')}
      </Button>
      <Typography variant="caption">
        {t('settings.app_version', { appVersion })}
      </Typography>
      {displayCacheRefresh && (
      <Typography component="span" variant="caption">
        {t('remote_config.should_refresh_more')}
      </Typography>
      )}
      {displayCacheRefresh && (
        <Button
          variant="contained"
          size="small"
          onClick={clearApplicationCache}
          endIcon={<Refresh />}
          sx={{ my: 1 }}
        >
          <Typography variant="caption">
            {t('clear_cache')}
          </Typography>
        </Button>
      )}
      {displayCacheRefresh && (
      <Link
        sx={{
          fontWeight: 'bold',
          fontSize: 14,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 0.3,
        }}
        target="_blank"
        href={t('remote_config.should_refresh_more_link')}
      >
        {t('remote_config.should_refresh_more_link')}
      </Link>
      )}
    </Paper>
  );
}
