import { fillingStatusFromValue } from 'components/utils/fillingType';
import { unit } from 'mathjs';
import Papa from 'papaparse';
import { round } from 'components/utils';
import { typeFromValue } from 'components/utils/containerType';
import decimalsMask from './decimalsMask';
import {
  calculateEvaporationForContainer, isContainerDataReady, mergeConversionResults,
  updateContainersArrayWithEmptyContainers,
} from './utils';

const getContainerReference = (item, inventory) => {
  const ivtContainerIdMapping = inventory.columnMappings.ivtContainerId.csvName;
  if (Array.isArray(ivtContainerIdMapping)) {
    return ivtContainerIdMapping.map((colName) => item[colName]).join(' / ');
  }
  return item[ivtContainerIdMapping];
};

export const calculateTotalEvaporation = (containers, columnMappings) => {
  // Calcul du % total d'évaporation = Somme (Volumes AP (N-1) )-
  // Somme (Volumes AP (N) uniquement les lignes calculées) / Somme (Volumes AP (N-1))
  const evaporationContainers = containers.filter((container) => !!container[columnMappings.evaporation.csvName]);
  if (evaporationContainers.length === 0) {
    return {};
  }

  const sumVolumeAPT = evaporationContainers.reduce((
    acc,
    container,
  ) => acc + Number(container[columnMappings.pureAlcoholTheoretical.csvName]), 0);

  const sumVolumeAP = evaporationContainers.reduce((
    acc,
    container,
  ) => acc + Number(container[columnMappings.pureAlcoholVolumeAt20cResult.csvName]), 0);

  const totalEvaporation = round((sumVolumeAPT - sumVolumeAP), decimalsMask[evaporationContainers[0].LBX_unite_volume]);
  const totalPercentEvaporation = round((totalEvaporation / sumVolumeAPT) * 100, 1);
  return {
    totalEvaporation,
    totalPercentEvaporation,
    unit: evaporationContainers[0].LBX_unite_volume,
  };
};

export const calculateTotalAP = (containers, columnMappings) => {
  const apContainers = containers.filter(
    (container) => !!container[columnMappings.pureAlcoholVolumeAt20cResult.csvName],
  );
  if (apContainers.length === 0) {
    return {};
  }
  const sumVolumeAP = apContainers.reduce((
    acc,
    container,
  ) => acc + Number(container[columnMappings.pureAlcoholVolumeAt20cResult.csvName]), 0);

  const totalAP = round(sumVolumeAP, decimalsMask[apContainers[0].LBX_unite_volume]);

  return {
    totalAP,
    unit: apContainers[0].LBX_unite_volume,
  };
};

/* eslint-disable import/prefer-default-export */
export const runConversions = async (
  setLoading,
  selectedRowIds,
  globalActions,
  containersArray,
  inventory,
  t,
  laboxAutomatedAlcoApiCall,
  setContainersArray,
  updateFirestoreDocument,
) => {
  setLoading(true);
  try {
    if (selectedRowIds.length === 0) {
      globalActions.setSnackbarMessage({
        message: `${t('automated_conversion.select_at_least_one')}`,
        severity: 'warning',
      });
      setLoading(false);
      return;
    }
    // Filter the containersArray based on selectedRowIds and exclude 'empty' status
    const selectedContainers = containersArray.filter(
      (container) => selectedRowIds.includes(container.id)
        && fillingStatusFromValue(container[inventory.columnMappings.filling.csvName]) !== 'empty',
    );

    const selectedEmptyContainers = containersArray
      .filter((container) => selectedRowIds.includes(container.id)
    && fillingStatusFromValue(container[inventory.columnMappings.filling.csvName]) === 'empty')
      .map((container, index) => ({
        ...container,
        fullArrayIndex: containersArray.findIndex((c) => c.id === container.id),
      }));

    const completeContainers = [];
    const inCompleteContainers = [];

    selectedContainers.forEach((container, index) => {
      const validation = isContainerDataReady(container, inventory, t);
      const fullArrayIndex = containersArray.findIndex((c) => c.id === container.id);
      if (validation.isReady) {
        completeContainers.push({ ...container, fullArrayIndex });
      } else {
        inCompleteContainers.push({ container, errors: validation.errors, fullArrayIndex });
      }
    });

    const csvData = completeContainers.map((selectedContainer) => {
      const convertToUnitOrReturnNull = (value, unitType, resultsVolumeUnit) => {
        if (!value) {
          return null;
        }
        return unit(value, unitType).toNumber(resultsVolumeUnit);
      };

      const resultsVolumeUnit = inventory.columnMappings.volumeAt20cResult.unit;

      const totalCapacityLiter = convertToUnitOrReturnNull(
        selectedContainer[inventory.columnMappings.totalCapacity.csvName],
        inventory.columnMappings.totalCapacity.unit,
        resultsVolumeUnit,
      );

      const usableCapacityLiter = convertToUnitOrReturnNull(
        selectedContainer[inventory.columnMappings.usableCapacity.csvName],
        inventory.columnMappings.usableCapacity.unit,
        resultsVolumeUnit,
      );

      const liquidVolumeLiter = convertToUnitOrReturnNull(
        selectedContainer[inventory.columnMappings.liquidVolume.csvName],
        inventory.columnMappings.liquidVolume.unit,
        resultsVolumeUnit,
      );

      const lengthUnit = inventory.columnMappings.heightUnderBung.unit;
      const temperatureUnit = 'celsius';
      const acloholUnit = 'TAV';

      const requestParams = {
        reference: getContainerReference(selectedContainer, inventory),
        volumeUnit: resultsVolumeUnit,
        temperatureUnit,
        acloholUnit,
        abvAtTemp: selectedContainer[inventory.columnMappings.tav.csvName],
        abvTemp: selectedContainer[inventory.columnMappings.tavTemperature.csvName],
        volumeTemp: selectedContainer[inventory.columnMappings.volumeTemperature.csvName],
        lengthUnit: inventory.columnMappings.heightUnderBung.unit,
        diameterUnderBung: selectedContainer[inventory.columnMappings.heightUnderBung.csvName],
        totalCapacity: totalCapacityLiter,
        usableCapacity: usableCapacityLiter,
        liquidHeight: selectedContainer[inventory.columnMappings.liquidHeight.csvName],
        emptyHeight: selectedContainer[inventory.columnMappings.emptyHeight.csvName],
        liquidVolume: liquidVolumeLiter,
        filling: fillingStatusFromValue(selectedContainer[inventory.columnMappings.filling.csvName]),
        type: inventory.columnMappings?.type
          ? typeFromValue(selectedContainer[inventory.columnMappings.type.csvName]) : undefined,
        volumePrecision: decimalsMask[resultsVolumeUnit],
        lengthPrecision: decimalsMask[lengthUnit],
        temperaturePrecision: decimalsMask[temperatureUnit],
        abvPrecision: decimalsMask[acloholUnit],
      };

      return requestParams;
    });

    const completeAndEmptyContainers = completeContainers.concat(selectedEmptyContainers);

    if (completeAndEmptyContainers.length > 0) {
      let updatedContainers = [...containersArray];

      if (selectedEmptyContainers.length > 0) {
      // update the empty containers volumes if any
        updatedContainers = updateContainersArrayWithEmptyContainers(
          updatedContainers,
          selectedEmptyContainers,
          inventory.columnMappings,
          t,
        );
      }

      if (completeContainers.length > 0) {
        const csvText = Papa.unparse(csvData);

        const conversionResults = await laboxAutomatedAlcoApiCall({
          call: 'alcoholometric-volume-conversion-ullaging-flexible',
          form: {
            csv: csvText,
          },
          app: 'inventory',
        });

        // Process and merge the results back into the containersArray
        updatedContainers = mergeConversionResults(
          updatedContainers,
          conversionResults.data.csv,
          selectedRowIds,
          t,
          inventory,
        );
      }

      // Evaporation
      completeAndEmptyContainers.forEach((completeContainer) => {
        const indexInUpdated = updatedContainers.findIndex((container) => container.id === completeContainer.id);
        if (indexInUpdated !== -1) {
          updatedContainers[indexInUpdated] = calculateEvaporationForContainer(
            updatedContainers[indexInUpdated],
            inventory.columnMappings,
          );
        }
      });

      setContainersArray(updatedContainers);
      let errorMessage;
      if (inCompleteContainers.length > 0) {
        const successMessage = `${t('calcuation_done_for_lines')} ${completeAndEmptyContainers.map(
          (info) => info.fullArrayIndex + 1,
        ).join(', ')}`;
        errorMessage = `${successMessage}\n\n`;
        errorMessage += inCompleteContainers.map(
          (info) => `${t('selected_line')} ${info.fullArrayIndex + 1} : ${info.errors.join(', ')}`,
        ).join('\n');
      }

      const severity = inCompleteContainers.length > 0 ? 'warning' : 'success';
      const message = inCompleteContainers.length > 0 ? errorMessage : t('success');
      const duration = inCompleteContainers.length > 0 ? 15000 : 2000;

      await updateFirestoreDocument(updatedContainers, severity, message, duration);
    } else if (inCompleteContainers.length > 0) {
      const errorMessage = inCompleteContainers.map(
        (info) => `${t('selected_line')} ${info.fullArrayIndex + 1} : ${info.errors.join(', ')}`,
      ).join('\n');
      const message = errorMessage;
      globalActions.setSnackbarMessage({
        message,
        severity: 'error',
      });
    }
  } catch (err) {
    globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
    throw err;
  } finally {
    setLoading(false);
  }
};
