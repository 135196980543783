import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fillingColumnOptions } from '../columnsUtils';
import OptionalWithSelectAutocomplete from './OptionalWithSelectAutocomplete';

function FillingAutocomplete({
  columnMappings,
  handleMappingChange,
  validCsvHeaders,
  isCsvHeaderValid,
  handleUnitChange,
  columnOptions,
  updateColumnOptions,
}) {
  const col = useMemo(() => fillingColumnOptions(columnOptions), [columnOptions]);
  const { t } = useTranslation();

  const updateOptions = (val, boolean) => {
    updateColumnOptions(col.name, {
      ...col,
      toFill: boolean,
      fillingValue: val,
    });
  };

  const onSelectChange = (newValue) => {
    updateColumnOptions(col.name, {
      ...col,
      fillingValue: newValue,
    });
  };

  const selectOptions = [t('inventory_item.filling.draining'), t('inventory_item.filling.full')];

  return (
    <OptionalWithSelectAutocomplete
      columnMappings={columnMappings}
      handleMappingChange={handleMappingChange}
      validCsvHeaders={validCsvHeaders}
      isCsvHeaderValid={isCsvHeaderValid}
      handleUnitChange={handleUnitChange}
      updateOptions={updateOptions}
      colOptions={col}
      defaultState={t('inventory_item.filling.draining')}
      onSelectChange={onSelectChange}
      selectOptions={selectOptions}

    />
  );
}

export default FillingAutocomplete;
