import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LengthUnitPicker(props) {
  const {
    lengthunit, setlengthunit, disabled = false, noLabel = false,
  } = props;
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined">
      {!noLabel && (
      <InputLabel id="length-select-label">{t('length_unit')}</InputLabel>)}
      <Select
        disabled={disabled}
        style={{ width: 170 }}
        defaultValue={lengthunit}
        label={noLabel ? null : t('length_unit')}
        id="length-select"
        value={lengthunit ?? ''}
        onChange={(e) => setlengthunit(e.target.value)}
        displayEmpty
      >
        <MenuItem value="cm">cm</MenuItem>
        <MenuItem value="in">in</MenuItem>
        <MenuItem value="ft">ft</MenuItem>
      </Select>
    </FormControl>
  );
}
